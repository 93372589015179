.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns with equal width */
  gap: 20px;
  padding: 16px;
}

.expandable-list-box {
  border: 1px solid white;
  border-radius: 10px;
  overflow: hidden;
}

.select-modifier-modal{
  height: 70vh;
}

.select-modifier-modal-body{
  overflow-y: auto;
  height: 70vh;
}

.grid-container {
  margin: 10px;
}

.select-mod-list-item {
  display: flex;
  justify-content: space-between;
  margin: 19px;
}

.sub-item {
  display: flex;
  padding-left: 16px;
}

.select-mod-sub-items {
  display: flex;
  flex-direction: column;
  padding: 20px;
}


.sub-item-list{
  display: flex;
  justify-content: space-between;
}


.select-mod-sub-item {
  display: flex;
  justify-content: space-between;
}

.icons-container {
  margin-left: auto;
  display: flex;
}

.item-name {
  padding-left: 10px;
}

.tile {
  border: 1px solid white;
  border-radius: 10px;
  overflow: hidden;
  background-color: $alice-blue;
  margin: 10px;
}


.modal-title{
  display: flex;
  justify-content: space-between;
}

h4{
  font-weight: 700;
}