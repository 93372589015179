.form-container {
    flex-direction: column;
    align-self: stretch;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
    display: flex;
}

.step-indicator {
    align-self: stretch;
    justify-content: center;
    align-items: center;
    gap: 5px;
    display: inline-flex;
    padding-top: 30px;
}

.step-icon {
    width: 19px;
    height: 19px;
    padding: 12px 12px 11px 12px;
    background: #2775FF;
    border-radius: 60px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.step-number {
    width: 15px;
    height: 12px;
    text-align: center;
    color: white;
    font-size: 14px;
    font-family: $font-medium;
    line-height: 10.53px;
    word-wrap: break-word;
}

.step-line {
    width: 9px;
    height: 9px;
    background: rgba(14, 32, 54, 0.60);
    border-radius: 9999px;
}

.account-creation {
    // flex-direction: column;
    // justify-content: flex-start;
    // align-items: flex-start;
    // gap: 16px;
    // display: flex;
}

.account{
    display: flex;
    width: 343px;
    height: 25px;
    flex-direction: column;
    justify-content: center;
} 

.account-text {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
}

.account-title, .account-subtitle {
    width: 343px;
    text-align: center;
    word-wrap: break-word;
}

.account-title {
    color: #0E2036;
    font-size: 22px;
    font-family: $font-medium;
    line-height: 19.95px;
}

.account-subtitle {
    color: rgba(14, 32, 54, 0.60);
    font-size: 16px;
    font-family: $font-normal;
    line-height: 12.03px;
}

#login-detail .form-label {
    width: 343px;
    // padding-top: 5px;
    // padding-bottom: 10px;
    padding-left: 5px;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    display: inline-flex;
}

#login-detail .form-group {
    margin-bottom: 10px;
}

#login-detail .label-text {
    color: rgba(14, 32, 54, 0.60);
    font-size: 14px;
    font-family: Jost;
    font-weight: 400;
    line-height: 10.53px;
    word-wrap: break-word;
}

// .input-field {
//     width: 343px;
//     height: 46px;
//     padding-left: 16px;
//     padding-right: 16px;
//     background: white;
//     border-radius: 16px;
//     border: 1px rgba(39.31, 116.56, 255, 0.06) solid;
//     justify-content: flex-start;
//     align-items: center;
//     display: inline-flex;
// }

// .input-text {
//     flex: 1 1 0;
//     height: 12px;
//     justify-content: flex-start;
//     align-items: center;
//     gap: 6px;
//     display: flex;
// }

// .input-placeholder {
//     color: rgba(14, 32, 54, 0.60);
//     font-size: 16px;
//     font-family: Jost;
//     font-weight: 400;
//     line-height: 12.03px;
//     word-wrap: break-word;
// }

.form-actions {
    height: 79px;
    padding: 16px;
    background: rgba(255, 255, 255, 0.80);
    box-shadow: 0px -20px 50px rgba(39, 117, 255, 0.15);
    border-top: 1px rgba(39.31, 116.56, 255, 0.15) solid;
    backdrop-filter: blur(40px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: flex;
    width: 403px;
    margin: -20px 0px;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
}

.action-buttons {
    align-self: stretch;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    display: inline-flex;
}

.back-button, .next-button {
    flex: 1 1 0;
    height: 47px;
    padding: 16px;
    border-radius: 16px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    display: flex;
    border: 0px;
}
.input-group-append{
    border-left:0px;
    // margin: 0px;
}

.button-text {
    text-align: center;
    font-size: 16px;
    font-family: $font-medium;
    line-height: 15.20px;
    word-wrap: break-word;
}

.back-button { background: rgba(0, 0, 0, 0.25); color: rgba(0, 0, 0, 0.25); }
.next-button { background: #2775FF; color: white; }

.set-your-emailpass{
    font-family: $font-medium;
    font-size:18px;
}