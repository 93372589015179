.service-details-container {
  padding: 24px;
  border-radius: 16px;
  gap: 24px;
  background-color: white;
  margin: 30px;
}
.header-col-1 {
  // width: 10%;
  margin-right: 16px;
}

.header-col-1-mobile {
  justify-content: center;
  width: 100%;
  align-items: center;
  display: flex;
}

.header-col-2 {
  width: 90%;
}
.header {
  display: flex;
}
.header-col-1-row-1 {
  display: flex;
  justify-content: space-between;
}

.header-col-1-row-1-actions {
  display: flex;
  gap: 10px;
}

.header-col-1-row-1-title {
  display: flex;
}
.divider {
  height: 1px;
  background-color: rgba($color: $scope-secondary, $alpha: 0.1);
  margin-top: 16px;
  margin-bottom: 16px;
}
.details-section-1,
.details-section-2 {
  display: flex;
  //   justify-content: space-between;
  // align-items: center;
}
.modifiers-title {
  display: flex;
}
.DetailsCategory-Componenet {
  width: 400px;
}
.Page-label {
  font-weight: 500;
  font-size: 18px;
  color: $scope-primary;
  font-family: $font-medium;
}
.action-icon{
  padding-right: 10px;
}

.label {
  margin: 0;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
  color: $scope-secondary;
}

.value {
  font-weight: 400;
  font-size: 16px;
  color: $scope-table-light;
}
.DetailsCategory-text-label {
  font-weight: 400;
  font-size: 14px;
  color: $scope-secondary;
}

.DetailsCategory-text-value {
  font-weight: 400;
  font-size: 14px;
  color: $scope-table-light;
}
.modifier-icon-title {
  color: $scope-primary;
  font-family: $font-medium;
  font-size: 18px;
  font-weight: 500;
}

.row.booking_url_row{
  flex-grow: 1;
  text-align: right;
  margin-right: 0px;
}
.booking_url_col{
  align-content: center;
}
.booking_url_element{
  width: 200px;
  float: right;
  text-align: center;
  background: #DFEAFF;
  padding: 10px;
  border-radius: 10px;
  font-weight: 600;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}
.link_icon{
  max-width: 20%;
  padding: 0;
}

.mobile_link_icon{
  display: flex;
  width: 100px;
  flex: 1;
  text-align: right;
}
.mobile_add_service_btn{
  flex:1;
  margin-left: 10px;
}
.add_service_btn{
  // padding: 0;
  text-align: center;
}

.column-serviceTitle{
  cursor: pointer;
}