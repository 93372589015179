.edit-template-head{
    align-items: center;
}
.description-head{
    margin:20px -10px 0;
}
.description-head .description-label{
    color: var(--Inactive-or-Neutral-Text-Color, rgba(14, 32, 54, 0.60));
    /* Body (400 Regular)/Body Text 14px */
    font-family: $font-medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}
.description-head .description-textarea textarea{
    border-radius: 16px;
    border: 1px solid var(--secondary-color-10, rgba(14, 32, 54, 0.10));
    background: #FFF;
    width: 100%;
    color: var(--Inactive-or-Neutral-Text-Color, #0e203699);
    font-family: $font-normal;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    padding: 10px;
}

.template-options-btn{
    margin: 0 10px 10px !important;
}
.leftIcon-templateOptions{
    margin-top:-5px;
}
// .label-templateOptions{
//     margin-left: -10px;
// }
.rightIcon-templateOptions{
    flex:10;
    text-align: right;
    margin-top:-5px;
}
.spacer.templateSpacer{
    height: 20px;
}
.scope-templetes-title{
    display: flex;
    align-items: center;
}
.scope-templetes-title div.editIcon{
    margin-left: 20px;
}
.scope-templetes-title {
    font-family: $font-medium !important;
    max-width: 100% !important;
}
.templateName-edit-buttons{
    margin-right: 30px !important;
}
.scope-templetes-title .templateName-edit{
    font-family: $font-medium;
    font-size:28px;
    background: transparent;
    border: 0;
    width: -webkit-fill-available;
    height: 40px;
    padding: 0;
}
.spinner-wrapper{
    z-index: 9999;
}

.table thead th {
    font-family: $font-normal;
}

.table th, .table td {
    border-left: solid 1px rgba(39, 117, 255, 0.2);
}
.table th:first-child, .table td:first-child {
    border-left: none;
}
.col {
    margin-right: 10px;
    margin-left: 10px;
}

@media (min-width: 1200px)  {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: 100%;
    }
}

.modal-scope {
    // max-width: 60%;
}
.modal-scope-40 {
    // max-width: 40%;
}
.modal-header .close {
    padding: 1rem 1rem;
    margin: -15px -5px -15px auto;
}
label {
    margin-bottom: 1rem;
}

.scope-sticky {
    position: sticky;
    top: 0px;
    z-index: 24;
    background: #f8faff;
    padding: 1px 0px;
    @media (max-width: 500px) {
        min-width: 500px;
    }
}

@media (max-width: 500px) {
    .plan-page-container {
        min-width: 400px;
        overflow-x: scroll;
    }    
}
.table label{
    margin-bottom:0px;
}

// .form-control {
//     margin: 10px 0px;
// }

.modal-90w.templateOptions{
    max-width: none;
    width: 90%;
}
.templateOptions #template-options-header{
    color: var(--Secondary-Color, #0E2036);
    text-align: center;
    font-family: $font-medium;
    font-size: 18px;
    font-style: normal;
    width: 100%;
    font-weight: 500;
    line-height: 75.2%; /* 13.536px */
}
.templateOptions .itemTemplateOptions{
    display: flex;
    align-items: center;
}
.templateOptions .collapse-btn{
    padding: 0;
}
.templateOptions .modal-header{
    padding:15px 10px 5px 10px;
    border-bottom:0px;
}

.templateOptions .addNewOption{
    border-radius: 16px;
    border: 1px solid #F2F7FF;
    background: #FFF;
    margin-left: -10px;
}
.addNewOption.error::placeholder{
    color: red;
    opacity: 1;
}
.modal-content{
    border-radius: 24px;
}
.templateOptions .modal-content{
    background-color: #fff;
}
.secondaryModal{
    box-shadow: 0 0 25px;
    border-radius: 24px;
    height: unset;
}
.secondaryModal .modal-header{
    border-bottom: 0;
}
.secondaryModal .modal-footer{
    border-top: 0;
}
.secondaryModal .modal-content{
    background-color: white;
}
#template-item-ref-add{
    display: flex;
}
.modal-body.body-danger{
    color: red;
}
.addChoiceOptionBtn{
    gap: 0 !important;
}

.list{
    border-radius: 16px;
}
.lastListGroup{
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
}
.listTitle{
    color: var(--Secondary-Color, #0E2036) !important;
    /* Body (500 Medium)/Body Medium 16px */
    font: 500 28px/90.7% Jost, -apple-system, Roboto, Helvetica,
      sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    padding: 16px;
    /* height: 68px; */
    line-height: 32px;
}
.list .listitem.label{
    color: var(--Secondary-Color, #0E2036) !important;
    /* Body (400 Regular)/Body Text 14px */
    font-family: $font-normal;
    // font: 500 28px/90.7% Jost, -apple-system, Roboto, Helvetica,
    //   sans-serif;
    font-size: 14px;
    font-style: normal;
    // font-weight: 400;
    padding: 16px;
    margin-bottom: 0;
    /* height: 56px; */
}
.list .listbody{
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
}
.list .listbody .dragIcon{
    /* flex: none; */
    width: 60px;
}
.list .list-group-item.active{
    border: 0px solid var(--secondary-color-10, rgba(14, 32, 54, 0.10));
    background: linear-gradient(0deg, rgba(39, 117, 255, 0.15) 0%, rgba(39, 117, 255, 0.15) 100%), #FFF;
}
.list .listitem .labelName{
    color: var(--Secondary-Color, #0E2036);
    // font: 500 28px/90.7% Jost, -apple-system, Roboto, Helvetica,
    //   sans-serif;
    font-family: $font-normal;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
    width: 100%;
}
.list .listTitle{
    justify-content: space-between;
    display: flex;
}
.templateOptions .modal-body{
 padding: 0px 30px;
}
.list .manage-actions{
    display: flex;
    justify-content: flex-end;
}

.edittemplatecard{
    border-radius: unset;
}

.itemheaders .manage-actions, .itemfooters .manage-actions, .itempageFooter .manage-actions {
    padding: 0px 10px;
}
.list .manage-actions .editIcon{

}
.list .manage-actions .trashIcon{

}

.list-group-item .titleLabel{
    transform: translateY(15%);
}

.itemEdit .modal-body .list-group-item {
    border: 0px solid rgba(0, 0, 0, 0.125);
}

.itemEdit .modal-body .list-group-item-action{
    background-color: #f8f9fa;
    border-radius: 16px;
    margin-bottom: 10px;
}

.list .editTemplate_btn{
    height: 39px;
}
.list .add-new-btn .button-label{
    color: var(--Primary, #2775FF);
    text-align: center;
    // font: 500 28px/90.7% Jost, -apple-system, Roboto, Helvetica,
    //   sans-serif;
    font-family: $font-medium;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 95%; /* 11.4px */
}
.list.list_headers .headerItem{
    display: flex;
    justify-content: space-between;
}
.subLabelName{
    color: var(--Tertiary-Color, #30455F);
    font-size: 12px;
    font-family: $font-normal;
    font-style: normal;
    font-weight: 300;
    line-height: 110%; /* 13.2px */
    padding-top: 10px;
    display: block;
}
.nestedlistitem .labelName{
    margin-left: 15px;
}
.nested-list .nestedlistitem{
    border-bottom: 0;
}
.nested-list .nestedlistitem:last-child{
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.list .nestedlistitem{
    display: flex;
    align-items: center;
}
.list .collapse-btn{
    padding: 0;
}
.list .addNewOption{
    border-radius: 16px;
    border: 1px solid #F2F7FF;
    background: #FFF;
    margin-left: -10px;
}
.addNewOption.error::placeholder{
    color: red;
    opacity: 1;
}
.addChoiceOptionBtn{
    gap: 0 !important;
}
.titleAction{
    display: flex;
    align-items: center;
}

@media only screen and (min-width: 767px) and (max-width: 1250px) {
    .listbuttons .button-label{
        display: none;
    }
    // .template-edit-action-btns .col-md-6 {
    //     flex: 0 0 55%;
    //     max-width: 100%;
    // }
    // .template-edit-action-btns {
    //     text-align: center;
    // }
}
.templateStructure .trash-icon{
    padding: 0;
}
.templateStructure .list .listbody .dragIcon{
    width: 20px;
}
.templateStructure .list .listitem .labelName{
    margin-left: 5px;
}
.templateStructure .subLabelName{
    max-width: 220px;
}
.listitem.label.item-choice{
    cursor: pointer;
}
.description-head .description-textarea textarea:focus{
    border-color: 1px solid #0E2036;
}
