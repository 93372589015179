.quileditor-container .ql-container.ql-snow{
    background-color: white;
    min-height: 500px;
}
.quileditor-container .ql-toolbar.ql-snow{
    // text-align: center;
    padding: 15px;
}
.quileditor-container .ql-toolbar.ql-snow .ql-formats{
    // border-right: 1px solid #ccc;
    // border-left: 1px solid #ccc;
    border: 1px solid #ccc;
    border-radius: 14px;
    padding: 5px;
    margin: 0 5px;
}
.quileditor-container .ql-toolbar.ql-snow .ql-formats .ql-active{
    background-color: #2775FF;
    border-radius: 10px;
    color:white !important;
}
.quileditor-container .ql-toolbar.ql-snow .ql-formats .ql-active .ql-stroke{
    stroke: white !important;
}
.quileditor-container .ql-toolbar.ql-snow .ql-formats > * {
    margin: 0 5px;
}
.ql-snow .ql-active .ql-stroke{
    stroke: white !important;
}
.ql-editor-footer {
    text-align: right;
    padding-right: 50px;
    border-bottom: 1px solid #ccc;
    color: gray;
    font-size: 12px;
}
.row-flex{
    display: flex;
    align-items: center;
}


.blockbuilder-branding, .kHVGZs .blockbuilder-branding{
    display: none !important;
}
.unlayer-editor-container .tab-content{
    height: 100% !important;
}

.ql-snow .ql-picker.vars .ql-picker-label::before, .ql-snow .ql-picker.vars .ql-picker-item::before{
    content: "Variables";
    width: 75px;
}
.ql-snow .ql-picker.vars .ql-picker-item[data-label="Client First Name"]::before{
    content: "Client First Name";
    font-size: 1em;
}
.ql-snow .ql-picker.vars .ql-picker-item[data-label="Client Last Name"]::before{
    content: "Client Last Name";
    font-size: 1em;
}
.ql-snow .ql-picker.vars .ql-picker-item[data-label="Client Email"]::before{
    content: "Client Email";
    font-size: 1em;
}
.ql-snow .ql-picker.vars .ql-picker-item[data-label="Inspector First Name"]::before{
    content: "Inspector First Name";
    font-size: 1em;
}
.ql-snow .ql-picker.vars .ql-picker-item[data-label="Inspector Last Name"]::before{
    content: "Inspector Last Name";
    font-size: 1em;
}
.ql-snow .ql-picker.vars .ql-picker-item[data-label="Inspector Email"]::before{
    content: "Inspector Email";
    font-size: 1em;
}


// TinyMCE Editor CSS
.tox.tox-silver-sink.tox-tinymce-aux{
    position: inherit !important;
}
// .tox-statusbar__branding{
//     display: none;
// }
// .tox-menu.tox-collection.tox-collection--list.tox-background-menu{
//     position: fixed !important;
// }

// Email Hooks Page CSS
.listitem.email-hook-list-item.list-group-item{
    display: flex;
    align-items: center;
}

.listitem.email-hook-list-item.list-group-item .labelName{
    font-size: 16px;
    font-weight: 600;
    flex: 2;
}

.listitem.email-hook-list-item.list-group-item .sentTo{
    flex:1;
    font-size: 12px;
}