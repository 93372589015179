.user-avatar {
  position: relative;
  .edit-avatar-icon {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    border: 2px solid $circle-img-border;
    position: absolute;
    background-color: #d8d8d8;
    bottom: 0;
    left: 70px;
    i {
      padding: 8px 0 0 7px;
      display: block;
    }
    @media (max-width:768px) {
      right: 165px;
    }
    @media (max-width:567px) {
      right: 35px;
    }
  }
}
.wrapper {
  .image-grouped {
    .text-avatar {
      border: 4px solid $circle-border;
      text-align: center;
      &:hover {
        border: 0;
      }
    }
  }
}
.userModalBody .row{
  padding: 10px;
  align-items: center;
}

.userModalBody select#select-user-type {
  color:  #0f1531;
}