.iconex-filled-check {
    height: 18px;
    position: relative;
    width: 18px;
  }
  
  .iconex-filled-check .line {
    height: 18px;
    left: 6px;
    position: absolute;
    top: 6px;
    width: 18px;
  }
  