.sidebar {
  min-height: calc(100vh - #{$navbar-height});
  background: $sidebar-light-bg;
  padding: 0;
  overflow-y:scroll;
  height: 500px;
  width: $sidebar-width-lg;
  z-index: 11;
  transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -webkit-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -moz-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -ms-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  border-right: solid 2px rgba(39, 117, 255, 0.2);
  .nav {
    overflow: hidden;
    flex-wrap: nowrap;
    flex-direction: column;
    &:not(.sub-menu) {
      padding-bottom: 10px;
    }

    .nav-item {
      padding:0 $sidebar-menu-padding-x;
      @include transition-duration(0.25s);
      transition-property: background;
      -webkit-transition-property: background;
      width: 100%;
      .ReactCollapse--collapse {
        transition: height 500ms;
      }
      .collapse {
        z-index: 999;
      }

      .nav-link {
        @include display-flex;
        @include align-items(center);
        white-space: nowrap;
        padding:$sidebar-menu-padding-y 0 $sidebar-menu-padding-y 0;
        color: $sidebar-light-menu-color;
        @include transition-duration(0.45s);
        transition-property: color;
        -webkit-transition-property: color;
        cursor: pointer;

        i {
          color: inherit;

          &.menu-icon {
            font-size: $sidebar-icon-font-size;
            line-height: 1;
            margin-left: left;
            .rtl & {
              margin-left: 0;
              margin-right: auto;
            }
            color: $sidebar-light-menu-icon-color;
            &:before {
              vertical-align: middle;
            }
          }

          &.menu-arrow {
            font: normal normal normal 24px/1 "Material Design Icons";
            line-height: 1;
            font-size: $sidebar-icon-font-size;
            margin-left: auto;
            transition: transform .2s ease-in-out;
            .rtl & {
              margin-left: 0;
              margin-right: auto;
            }
            color: $sidebar-light-menu-arrow-color;
            &:before{
              content: "\f141";
              font-size: inherit;
              color: inherit;
              .rtl & {
                content: "\f142";
              }
            }
            + .menu-icon {
              margin-left: .25rem;
              margin-bottom: .25rem;
              .rtl & {
                margin-left: 0;
                margin-right: .25rem;
              }
            }
          }
        } 

        .menu-title {
          color: inherit;
          // display: inline-block;
          font-size: 12px; //$sidebar-menu-font-size;
          line-height: 1;
          vertical-align: middle;
          font-weight:$font-weight-normal,
        }

        .badge {
          margin-right: auto;
          margin-left: 1rem;
        }

        &.menu-expanded {
          .menu-arrow{
              transform: rotate(-90deg);
          }
        }
      }

      &.active {
        .nav-link {
          .menu-title {
            color: $sidebar-light-menu-active-color;
            font-weight: $font-weight-medium;         
          }
          i {
            color: theme-color(primary);
          }
        }
      }

      &:hover{
        background: $sidebar-light-menu-hover-bg;
      }
      &.nav-profile {
        .nav-link {
          height: auto;
          line-height: 1;
          border-top: 0;
          padding: 1.25rem 0;
          .nav-profile-image {
            width: 44px;
            height: 44px;
            img {
              width: 44px;
              height: 44px;
              border-radius: 100%;
            }
          }
          .nav-profile-text {
            display: flex;
            flex-direction: column;
            margin-left: 1rem;
            .rtl & {
              margin-left: auto;
              margin-right: 1rem;
            }
          }
          .nav-profile-badge {
            font-size: 1.125rem;
            margin-left: auto;
            .rtl & {
              margin-left: 0;
              margin-right: auto;
            }
          }
        }
      }
      &.sidebar-actions {
        margin-top: 1rem;
        .nav-link {
          border-top: 0;
          display: block;
          height: auto;
        }
        &:hover {
          background: initial;
          .nav-link {
            color: initial;
          }
        }
      }
    }

    &:not(.sub-menu) {
      > .nav-item {
        &:hover {
          &:not(.nav-category):not(.nav-profile) {
            > .nav-link {
              color: $sidebar-light-menu-hover-color;
            }
          }
        }
      }
    }
    &.sub-menu {
      margin-bottom: 20px;
      margin-top:0;
      list-style: none;

      .nav-item {
        padding: 0;
        .nav-link {
          color: $sidebar-light-submenu-color;
          padding: $sidebar-submenu-item-padding;
          position: relative;
          font-size: $sidebar-submenu-font-size;
          line-height: 1;
          height: auto;
          border-top: 0;
          &:before {
            content: "\F054";
            font-family: "Material Design Icons";
            display: block;
            position: absolute;
            left:0px;
            .rtl & {
              left: auto;
              right: 0;
              content: "\F04D";
            }
            top:50%;
            @include transform(translateY(-50%));
            color: lighten($sidebar-light-submenu-color, 10%);
            font-size: .75rem;
          }
          &.active {
            color: $sidebar-light-menu-active-color;
            background: transparent;
          }
          &:hover {
            color: $sidebar-light-submenu-hover-color;
          }
        }
        &:hover {
          background: transparent;
        }
      }
    }
  }
}


/* style for off-canvas menu*/
// @media screen and (max-width: 991px) {
  .sidebar-offcanvas {
    position: fixed;
    max-height: calc(100vh - #{$navbar-height});
    top: $navbar-height;
    bottom: 0;
    // overflow: hidden;
    left: -$sidebar-width-lg;
    -webkit-transition: all 0.25s ease-out;
    -o-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
    &.active {
      left: 0;
    }
    @media (max-width: 700px) { 
      padding-bottom: 150px;
    }
  }
// }

.sidebar-offcanvas:hover {
  overflow-y: scroll;
  scrollbar-width: thin;

}
.sidebar-offcanvas:hover::-webkit-scrollbar-thumb {
  // background-color: $scope-secondary;
  outline: 1px solid $scope-primary;
}

.sidebar-offcanvas:hover::-webkit-scrollbar {
  width: 1px;
}

.main-panel::-webkit-scrollbar-thumb{
  background-color: $scope-secondary;
  outline: 1px solid $scope-secondary;
}
.main-panel::-webkit-scrollbar{
  width: 3px;
}

.menu-divider{
  height: 1px;
  background-color: rgba($color: $scope-secondary, $alpha: 0.1);
}

.sidebar-offcanvas:hover::-webkit-scrollbar-track {
  // background-color: $scope-secondary;
}

@media screen and (max-width: 700px) {
  .sidebar-offcanvas {
    
    max-height: calc(100vh - #{$mobile-navbar-height});
  }
  .main-panel{
    // padding-top: $navbar-height;
    max-height: calc(100vh - #{$mobile-navbar-height});
  }
  .has-company .main-panel {
    padding-top: $navbar-height !important;
  }
  .has-company .sidebar-offcanvas {
    padding-top: $navbar-height !important;
  }

}
.main-panel{
  overflow-x: hidden;
}
// @media screen and (max-width: 380px) {
//   .sidebar-offcanvas {
//     top: 140px;
//   }
//   .page-body-wrapper {
//     padding-top: 140px !important;
//   }

// }

.menu-separator-spacing{
  display: flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.menu-separator{
  width: 183px;
  height: 1px;
  background: rgba(14, 32, 54, 0.10);
}

.sub-menu-shevron{
  padding: 0px 50px 0px 0px;
}
.menu-shevron-expanded{
  transform: rotate(90deg);
}

.sidebar-divider{
  width: 80%;
  margin: 0 auto;
  margin-bottom:10px;
}