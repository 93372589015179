.itemEdit .modal-header{
    text-align: center;
    display: block;
    background-color: #fff;
    border-bottom: 0px solid #ebedf2;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
}

.itemEdit .add-new-item{
    display: inline-block;
    padding: 0px 10px 0px 0px;
}
.itemEdit .dublicate-item{
    display: inline-block;
    padding: 0px 10px 0px 0px;
}

.itemEdit .dublicate-item .clone-icon{
    padding: 4px 9px 6px 9px;
    border-radius: unset;
    background: unset;
}

.modal-footer {
    border-top: 0px solid #ebedf2;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    background-color: #fff;
}
.modal-dialog{
    // width: 100%;
    // height: 100%;
    position: relative; 
    background: rgba(255, 255, 255, 0.01); 
    backdrop-Filter: blur(40px);
}

