

.login-container {
    background-color: var(--Page-BG, #f8faff);
    display: flex;
    flex-direction: column;
    padding: 0 20px;
  }
  .auth-form-login {
    justify-content: center;
    align-items: center;
    align-self: center;
    display: flex;
    width: 403px;
    max-width: 100%;
    flex-direction: column;
    margin: 90px 0 85px;
  }
  @media (max-width: 991px) {
    .auth-form-login {
      margin: 40px 0;
    }
  }
  .welcome-back-login {
    // align-items: flex-start;
    align-self: center;
    display: flex;
    width: 243px;
    max-width: 100%;
    flex-direction: column;
  }
  .welcome-back-title {
    color: var(--Secondary-Color, #0e2036);
    text-align: center;
    letter-spacing: 0.28px;
    font: 500 28px/90.7% Jost, -apple-system, Roboto, Helvetica,
      sans-serif;
      padding-bottom: 16px;
  }
  .img {
    aspect-ratio: 4.96;
    object-fit: cover;
    object-position: center;
    width: 100%;
    overflow: hidden;
    align-self: stretch;
    margin-top: 16px;
    flex-grow: 1;
  }
  .scope-form {
    align-items: flex-start;
    border-radius: 24px;
    box-shadow: 0px 29px 50px 0px rgba(39, 117, 255, 0.15);
    background-color: #fff;
    align-self: stretch;
    display: flex;
    margin-top: 40px;
    width: 100%;
    flex-grow: 1;
    flex-direction: column;
    padding: 30px 20px 20px;
    // padding: 30px 20px 46px;
  }
  .login-with {
    align-self: center;
    color: var(--Secondary-Color, #0e2036);
    text-align: center;
    width: 100%;
    font: 500 24px/90.7% Jost, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  .social-buttons {
    justify-content: center;
    align-items: flex-start;
    align-self: center;
    display: flex;
    margin-top: 30px;
    width: 100%;
    gap: 14px;
  }
  .splash .social-buttons{
    margin-top: 0px;
  }
  .splash {
    margin-top: 90px;
  }
  .signup-title {
    align-self: stretch;
    text-align: center;
    color: black;
    font-size: 28px;
    font-family: $font-medium;
    line-height: 25.40px;
    letter-spacing: 0.28px;
    word-wrap: break-word;
  }
  .auth-form-splash-signup {
    justify-content: center;
    align-items: center;
    align-self: center;
    display: flex;
    width: 403px;
    max-width: 100%;
    flex-direction: column;
    margin: 10px 0 85px;
  }

  .content-container {
    // left: 105px;
    // top: 60px;
    // position: absolute;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    display: inline-flex;
  }
  .login-here {
    color: #2775FF;
  }

  .already-account-container {
    align-self: stretch;
    justify-content: center;
    align-items: center;
    gap: 14px;
    display: inline-flex;
  }

  .already-account, .login-here {
      text-align: center;
      font-size: 24px;
      font-family: $font-normal;
      line-height: 21.77px;
      word-wrap: break-word;
  }

  .social-fb {
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    border: 1px solid
      var(--Stroke-Color-For-Inactive-Dropdowns-and-Comments, #e7e7e7);
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 16px 20px;
    background-color: #fff;
  }
  .img-2 {
    aspect-ratio: 1;
    object-fit: cover;
    object-position: center;
    width: 24px;
    overflow: hidden;
    align-self: center;
    max-width: 100%;
  }
  .social-google {
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    border: 1px solid
      var(--Stroke-Color-For-Inactive-Dropdowns-and-Comments, #e7e7e7);
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 16px 20px;
    background-color: #fff;
    min-width:150px;
  }
  .img-3 {
    aspect-ratio: 1;
    object-fit: cover;
    object-position: center;
    width: 24px;
    overflow: hidden;
    align-self: center;
    max-width: 100%;
  }
  .social-apple {
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    border: 1px solid
      var(--Stroke-Color-For-Inactive-Dropdowns-and-Comments, #e7e7e7);
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 16px 20px;
    background-color: #fff;
    min-width:150px;
  }
  .img-4 {
    aspect-ratio: 1;
    object-fit: cover;
    object-position: center;
    width: 24px;
    overflow: hidden;
    align-self: center;
    max-width: 100%;
  }
  .or-box{
    align-self: stretch; padding-top: 8px; padding-bottom: 8px; justify-content: center; align-items: center; gap: 8px; display: inline-flex;
  }
  .or-line{
    flex: 1 1 0; height: 0px; border: 1px #E7E7E7 solid
  }
  .or-title{
    text-align: center; color: rgba(14, 32, 54, 0.60); font-size: 14px; font-family: Jost; font-weight: 400; line-height: 10.53px; word-wrap: break-word
  }
  .or-login {
    color: var(--Inactive-or-Neutral-Text-Color, rgba(14, 32, 54, 0.6));
    text-align: center;
    align-self: center;
    text-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
    width: 13px;
    max-width: 100%;
    font: 400 14px/75.2% Jost, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
 
  @media (max-width: 991px) {
    .or-login {
      text-wrap: initial;
    }
  }
  .login-form {
    align-items: flex-start;
    align-self: center;
    display: flex;
    margin-top: 20px;
    width: 100%;
    padding-top: 7px;
    flex-direction: column;
  }
  .email-box {
    align-items: flex-start;
    align-self: start;
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .email-title {
    color: var(--Inactive-or-Neutral-Text-Color, rgba(14, 32, 54, 0.6));
    align-self: start;
    text-wrap: nowrap;
    font: 400 14px/75.2% Jost, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .email-title {
      text-wrap: initial;
    }
  }
  .email-field {
    color: var(--Inactive-or-Neutral-Text-Color, rgba(14, 32, 54, 0.6));
    align-self: start;
    text-wrap: nowrap;
    align-items: center;
    border-radius: 16px;
    // border: 1px solid var(--primary-color-6, rgba(39, 117, 255, 0.06));
    background-color: #fff;
    margin-top: 10px;
    width: 100%;
    flex-grow: 1;
    padding: 17px 20px 17px 16px;
    font: 400 16px/75.2% Jost, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .email-field {
      text-wrap: initial;
    }
  }
  .password-box {
    align-items: flex-start;
    align-self: start;
    display: flex;
    // margin-top: 23px;
    width: 100%;
    flex-direction: column;
    margin-bottom:0;
  }
  .password-title {
    color: var(--Inactive-or-Neutral-Text-Color, rgba(14, 32, 54, 0.6));
    align-self: start;
    text-wrap: nowrap;
    font: 400 14px/75.2% Jost, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .password-title {
      text-wrap: initial;
    }
  }
  .password-field {
    align-items: flex-start;
    align-self: start;
    border-radius: 16px;
    // border: 1px solid var(--primary-color-6, rgba(39, 117, 255, 0.06));
    background-color: #fff;
    display: flex;
    margin-top: 10px;
    width: 100%;
    justify-content: space-between;
    gap: 20px;
    padding: 14px 16px;
  }

  .scope-checkbox::before{
    width: 16px !important;
    height: 15px !important;
    border-radius: 16px !important;
  }
  .pass-eye {
    background: unset;
    border-radius: 0px 16px 16px 0px;
    margin-top: 10px;
  }
  .div-19 {
    color: var(--Inactive-or-Neutral-Text-Color, rgba(14, 32, 54, 0.6));
    margin: auto 0;
    font: 400 16px/75.2% Jost, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  .img-5 {
    aspect-ratio: 1;
    object-fit: cover;
    object-position: center;
    width: 18px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    align-self: stretch;
    max-width: 100%;
  }
  .remember-me {
    align-items: flex-start;
    align-self: start;
    display: flex;
    margin-top: 16px;
    width: 170px;
    max-width: 100%;
    gap: 6px;
  }
  .img-6 {
    aspect-ratio: 1;
    object-fit: cover;
    object-position: center;
    width: 17px;
    overflow: hidden;
    align-self: stretch;
    max-width: 100%;
  }
  .remember-me-title {
    color: #444;
    margin: auto 0;
    font: 500 14px/75.2% Jost, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  .login-button {
    justify-content: center;
    align-items: center;
    align-self: center;
    border-radius: 16px;
    background-color: var(--Primary, #2775ff);
    display: flex;
    margin-top: 30px;
    width: 100%;
    flex-direction: column;
    padding: 16px 20px;
    cursor: pointer;
    border: 0px;
  }
  .login-button-title {
    color: #fff;
    text-align: center;
    align-self: center;
    text-wrap: nowrap;
    font: 500 16px/95% Jost, sans-serif;
    background-color:unset;
    border-color:unset;
    border: 0px;
   
  }
  .login-button-title:active, .login-button-title:focus, .login-button-title:hover {
    background-color:unset;
    border-color:unset;
    border: 0px;
  }
  @media (max-width: 991px) {
    .login-button-title {
      text-wrap: initial;
    }
  }
  .forgot-link {
      color: var(--Success, #11c979);
      font-family: $font-medium;
  }
  .forgot {
    text-align: center;
    align-self: center;
    margin-top: 38px;
    text-wrap: nowrap;
    font: 500 14px/75.2% Jost, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .forgot {
      text-wrap: initial;
    }
  }
  .login-sign-up-link {
      color: var(--Primary, #2775ff);
      font-family: $font-medium;
  }
  .login-sign-up-link:hover{
    text-decoration: none;
  }
  .forgot-link:hover{
    text-decoration: none;
  }
  .login-sign-up {
    text-align: center;
    align-self: center;
    margin-top: 54px;
    text-wrap: nowrap;
    font: 500 16px/75.2% Jost, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .login-sign-up {
      margin-top: 40px;
      text-wrap: initial;
    }
  }