.iconex-filled-star {
    height: 24px;
    width: 24px;
  }
  
  .iconex-filled-star .star {
    height: 22px;
    left: 1px;
    position: relative;
    top: 1px;
    width: 22px;
  }
  
  .iconex-filled-star .img {
    height: 20px;
    left: 1px;
    position: absolute;
    top: 0;
    width: 20px;
  }
  