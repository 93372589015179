.expandable-list-container {
  border: 1px solid rgba($color: $scope-primary, $alpha: 0.15);
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
}

@media (min-width: 992px) {
  .expandable-list-container {
    width: 50%;
  }
}

.items-list.bordered {
  border-bottom: 1px solid rgba($color: $scope-primary, $alpha: 0.15);
}

.sub-items {
    padding-left: 26px;
    padding-right: 60px;
}

.list-item {
  display: flex;
  background-color: white;
  justify-content: space-between;
}
.tile-actions{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-right: 0;
}
.sub-item{
    display: flex;
    justify-content: space-between;
}

.sub-item-value{
   color: $scope-primary;
}

.sub-item-list{
  padding-left: 26px;
  padding-right: 26px;
}

.modifier-name{
  font-size: 16px;
  font-family: $font-normal;
  font-weight: 400;
}

.option-name{
font-family: $font-light;
font-size: 14px;
font-weight: 300;
line-height: 10.53px;
text-align: left;
}

.option-value{
font-family: $font-medium;
font-size: 12px;
font-weight: 500;
line-height: 9.02px;
text-align: right;
color: $scope-primary;
}
.Empty-Section{
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}