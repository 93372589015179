.scope-services-heading {
    align-items: flex-start;
    background-color: var(--Page-BG, #f8faff);
    display: flex;
    justify-content: space-between;
    gap: 16px;
    padding: 10px;
    min-height: 60px;
  }

  .scope-services-title {
    color: var(--Secondary-Color, #0e2036);
    letter-spacing: 0.28px;
    align-self: center;
    max-width: 542px;
    flex-grow: 1;
    flex-basis: auto;
    margin: auto 0;
    font: 500 28px/90.7% Jost;
    font-family: $font-medium;
  }
  .scope-services-add-new-button {
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 16px;
    border: 1px solid $scope-primary;
    align-self: stretch;
    display: flex;
    max-width: 100%;
    gap: 7px;
    padding: 1px 20px;
    cursor: pointer;
  }

  .scope-services-add-new-title {
    color: var(--Primary, #2775ff);
    text-align: center;
    align-self: center;
    margin: auto 0;
    font-family: $font-medium;
  }