/* Icons */

.icons-list {
  border-left: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  >div {
    background: $white;
    border-top: 1px solid $border-color;
    border-right: 1px solid $border-color;
    @include display-flex;
    @include align-items(center);
    padding:15px 15px;
    font-size: $default-font-size;

    i {
      display: inline-block;
      font-size: 20px;
      width: 40px;
      text-align: left;
      color: theme-color(primary);
    }
  }
}

.edit-icon {
  padding: 4px 6px 6px 6px;
  border-radius: 20px;
  background: #DFEAFF;
}
.edit-icon-blue {
  padding: 1px 10px 6px 10px;
  border-radius: 30px;
  background: $scope-primary;
}
.link-icon {
  padding: 4px 8px 6px 8px;
  border-radius: 20px;
  background: #DFEAFF;
}
.clone-icon {
  padding: 4px 9px 6px 9px;
  border-radius: 20px;
  background: #DFEAFF;
}
.make-admin-icon {
  padding: 4px 5px 7px 8px;
  border-radius: 20px;
  background: #DFEAFF;
}
.make-user-icon {
  padding: 4px 5px 7px 8px;
  border-radius: 20px;
  background: #DFEAFF;
}
.delete-icon {
  padding: 4px 9px 6px 9px;
  border-radius: 20px;
  background: #FFF5F5;
}
.block-user-icon {
  padding: 2px 5px 6px 5px;
  border-radius: 20px;
  background: #FFF5F5;
}

.active .delete-icon {
  background: #FFF5F5;
}
.active .link-icon {
  background: #F2F7FF;
}
.active span.edit-icon{
  background: #F2F7FF;
}
