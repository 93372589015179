@mixin social-button($color) {
  background: $color;
  color: $white;

  &:hover,
  &:focus {
    background: darken($color, 10%);
    color: $white;
  }
  &.btn-social-icon-text {
    padding: 0 1.5rem 0 0;
    background: lighten($color, 10%);
    i {
      background: $color;
      padding: .75rem;
      display: inline-block;
      margin-right: 1.5rem;
    }
  }
}
@mixin social-outline-button($color) {
border: 1px solid $color;
color: $color;
&:hover {
  background: $color;
  color: $white;
}
}
@mixin button-inverse-variant($color, $color-hover: $white) {
  background-color: rgba($color, 0.2);
  background-image: none;
  border-color: rgba($color, 0);
  &:not(.btn-inverse-light) {
    color: $color;      
  }
  @include hover {
    color: $color-hover;
    background-color: $color;
    border-color: $color;
  }

  &.focus,
  &:focus {
    box-shadow: 0 0 0 3px rgba($color, .5);
  }

  &.disabled,
  &:disabled {
    color: $color;
    background-color: transparent;
  }

  &.active,
  &:active,
  .show > &.dropdown-toggle {
    color: $color-hover;
    background-color: $color;
    border-color: $color;
  }
}
@mixin button-gradient-variant($value) {
  background: $value;
  border: 0;
  &:not([disabled]):not(.disabled):active,
  &:not([disabled]):not(.disabled).active,
  .show > &.dropdown-toggle {
    background: $value;
  }
  &:hover {
    opacity: .8;
  }
  &:not(.btn-gradient-light) {
    color: $white;      
    &:hover,
    &:focus,
    &:active {
      color: $white;
    }
  }
  @include transition(opacity .3s ease);
}



.button {
  all: unset;
  align-items: center;
  border-radius: 16px;
  box-sizing: border-box;
  justify-content: center;
  position: relative;
}

.button .class {
  width: 21px !important;
}

.button .class-2 {
  width: 19px !important;
}

.button .class-3 {
  margin-bottom: -8px !important;
  margin-top: -8px !important;
  position: relative !important;
}

.button .class-4 {
  margin-left: -8.5px !important;
  position: relative !important;
}

.button .class-5 {
  margin-left: -7px !important;
  opacity: 0.25 !important;
  position: relative !important;
  width: 22.5px !important;
}

.button .class-6 {
  margin-left: -7px !important;
  position: relative !important;
  width: 22.5px !important;
}

.button .class-7 {
  margin-left: -8.5px !important;
  opacity: 0.25 !important;
  position: relative !important;
}

.button .class-8 {
  margin-left: -8.5px !important;
  opacity: 0.4 !important;
  position: relative !important;
}

.button .button-label {
  font-family: $font-medium;
  // font-family: "Jost", Helvetica;
  font-weight: 500;
  letter-spacing: 0;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.stripe-action-button .button .button-label {
  white-space:unset;
}

.button .class-9 {
  margin-right: -8.5px !important;
  position: relative !important;
}

.button .class-10 {
  margin-right: -7px !important;
  opacity: 0.25 !important;
  position: relative !important;
  width: 22.5px !important;
}

.button .class-11 {
  margin-right: -8.5px !important;
  position: relative !important;
  transform: rotate(180deg) !important;
}

.button .class-12 {
  margin-right: -7px !important;
  position: relative !important;
  width: 22.5px !important;
}

.button .class-13 {
  margin-right: -8.5px !important;
  opacity: 0.25 !important;
  position: relative !important;
}

.button .class-14 {
  margin-right: -8.5px !important;
  opacity: 0.4 !important;
  position: relative !important;
}

.button.large {
  display: inline-flex;
  gap: 6px;
  height: 45px;
  padding: 10px 20px;
  min-width: 85px;
}



@media screen and (max-width: 1024px) {
  .titleAction .button.large, .manage-actions .button.large{
    min-width: unset;
    padding:5px 15px 0px 15px;
   }
}

button:disabled{
  background-color: #ccc;
  cursor: unset;
}

.button.custom-btn{
  // padding: 12px 30px 8px 30px;
}
.button.custom-btn.template-option-add{
  padding: 15px
}
.button.small {
  display: flex;
  gap: 5px;
  padding: 10px 16px;
  width: 150px;
  margin: 5px;
  @media screen and (max-width: 768px) {
    width: 110px;
  }  
  @media screen and (max-width: 415px) {
    width: 90px;
  }  
}

.button.focused {
  border: 2px solid;
}

.button.pressed.error {
  border-color: var(--danger-caution);
}

.button.focused.error {
  background-color: var(--danger-caution);
  border-color: #0000001a;
}

.button.outlined.pressed {
  border: 1px solid;
}

.button.pressed.error {
  background-color: #00000033;
}

.button.outlined.default {
  border: 1px solid;
}

.button.pressed.ghost {
  border: 0px none;
}

.button.outlined.disabled {
  border: 1px solid;
}

.button.disabled.error {
  background-color: #ff3a3a66;
}

.button.ghost.disabled {
  border: 0px none;
}

.button.default.error {
  border-color: var(--danger-caution);
}

.button.error.disabled {
  border-color: #ff3a3a66;
}

.button.disabled.filled {
  background-color: #0000001a;
}

.button.filled.default.secondary {
  background-color: var(--secondary-color);
}

.button.focused.filled.primary {
  background-color: var(--primary);
  border-color: #2774ff;
}

.button.outlined.focused.error {
  background-color: #ff3a3a1a;
  border-color: var(--danger-caution);
}

.button.pressed.ghost.primary {
  background-color: #2775ff1a;
  border-color: var(--primary);
}

.button.outlined.pressed.primary {
  background-color: #2775ff1a;
  border-color: var(--primary);
}

.button.focused.ghost.error {
  background-color: #ff3a3a1a;
  border-color: var(--danger-caution);
}

.button.outlined.pressed.error {
  background-color: #ff3a3a1a;
}

.button.focused.ghost.primary {
  background-color: #2775ff1a;
  border-color: var(--primary);
}

.button.outlined.focused.secondary {
  background-color: #30455f1a;
  border-color: var(--secondary-color);
}

.button.outlined.default.primary {
  border-color: var(--primary);
}

.button.outlined.default.secondary {
  border-color: var(--secondary-color);
}

.button.outlined.pressed.secondary {
  background-color: #30455f1a;
  border-color: var(--secondary-color);
}

.button.focused.ghost.secondary {
  background-color: #30455f1a;
  border-color: var(--secondary-color);
}

.button.outlined.disabled.primary {
  border-color: #00000040;
}

.button.outlined.focused.primary {
  background-color: #2775ff1a;
  border-color: var(--primary);
}

.button.pressed.ghost.error {
  background-color: #ff3a3a1a;
}

.button.ghost.disabled.secondary {
  border-color: var(--secondary-color);
}

.button.filled.default.primary {
  background-color: var(--primary);
}

.button.pressed.filled.primary {
  background-color: #00000033;
}

.button.pressed.error.filled {
  background-color: #ff3a3a1a;
}

.button.focused.error.filled {
  background-color: #ff3a3a1a;
  border-color: var(--danger-caution);
}

.button.outlined.disabled.secondary {
  border-color: #00000040;
}

.button.pressed.filled.secondary {
  background-color: #0000004c;
  border: 1px solid;
  border-color: #30455f;
}

.button.ghost.disabled.primary {
  border-color: var(--primary);
}

.button.pressed.ghost.secondary {
  background-color: #30455f1a;
  border-color: var(--secondary-color);
}

.button.focused.filled.secondary {
  background-color: var(--secondary-color);
  border-color: #30455f;
}

.button.large .button-label {
  font-size: 14px;
  line-height: 15.2px;
}

.button.small .button-label {
  font-size: 14px;
  line-height: 13.3px;
}

.button.focused.error .button-label {
  color: #ffffff;
}

.button.focused.large .button-label {
  margin-bottom: -1.5px;
  margin-top: -5.5px;
}

.button.focused.filled .button-label {
  color: #ffffff;
}

.button.default.large .button-label {
  margin-bottom: -2.5px;
  margin-top: -4.5px;
}

.button.pressed.error .button-label {
  color: #ffffff;
}

.button.pressed.filled .button-label {
  color: #ffffff;
}

.button.ghost.disabled .button-label {
  color: #00000040;
}

.button.default.error .button-label {
  color: #ffffff;
}

.button.default.filled .button-label {
  color: #ffffff;
}

.button.disabled.filled .button-label {
  color: #00000040;
}

.button.disabled.large.filled .button-label {
  margin-bottom: -2.5px;
  margin-top: -4.5px;
}

.button.disabled.ghost.large .button-label {
  margin-bottom: -3.5px;
  margin-top: -3.5px;
}

.button.error.disabled.secondary .button-label {
  color: #00000066;
}

.button.error.disabled.error .button-label {
  color: #ff3a3a66;
}

.button.default.ghost.error .button-label {
  color: var(--danger-caution);
}

.button.error.disabled.primary .button-label {
  color: #00000066;
}

.button.outlined.focused.error .button-label {
  color: var(--danger-caution);
}

.button.pressed.ghost.primary .button-label {
  color: var(--primary);
}

.button.outlined.pressed.primary .button-label {
  color: var(--primary);
}

.button.focused.ghost.error .button-label {
  color: var(--danger-caution);
}

.button.outlined.pressed.error .button-label {
  color: var(--danger-caution);
}

.button.focused.ghost.primary .button-label {
  color: var(--primary);
}

.button.pressed.large.filled .button-label {
  margin-bottom: -2.5px;
  margin-top: -4.5px;
}

.button.outlined.focused.secondary .button-label {
  color: var(--secondary-color);
}

.button.outlined.default.error .button-label {
  color: var(--danger-caution);
}

.button.outlined.default.primary .button-label {
  color: var(--primary);
  font-family: $font-medium;
}

.button.outlined.default.secondary .button-label {
  color: var(--secondary-color);
}

.button.outlined.pressed.secondary .button-label {
  color: var(--secondary-color);
}

.button.focused.ghost.secondary .button-label {
  color: var(--secondary-color);
}

.button.disabled.large.error .button-label {
  margin-bottom: -2.5px;
  margin-top: -4.5px;
}

.button.outlined.disabled.primary .button-label {
  color: #00000040;
}

.button.default.ghost.secondary .button-label {
  color: var(--heading-color);
}

.button.outlined.disabled.large .button-label {
  margin-bottom: -2.5px;
  margin-top: -4.5px;
}

.button.outlined.focused.primary .button-label {
  color: var(--primary);
}

.button.pressed.ghost.error .button-label {
  color: var(--danger-caution);
}

.button.pressed.ghost.large .button-label {
  margin-bottom: -3.5px;
  margin-top: -3.5px;
}

.button.outlined.disabled.secondary .button-label {
  color: #00000040;
}

.button.pressed.large.error .button-label {
  margin-bottom: -2.5px;
  margin-top: -4.5px;
}

.button.outlined.pressed.large .button-label {
  margin-bottom: -2.5px;
  margin-top: -4.5px;
}

.button.outlined.error.disabled .button-label {
  color: #ff3a3a66;
}

.button.pressed.ghost.secondary .button-label {
  color: var(--secondary-color);
}

.button.default.ghost.large.primary .button-label {
  color: var(--secondary-color);
}

.button.ghost.default.small.primary .button-label {
  color: var(--heading-color);
}
.button.custom-btn.primary.filled.disabled{
  background-color: #0000001a !important;
}
.button.custom-btn.primary.ghost.disabled .button-label{
  color: #0000001a !important;
}
