:root {
    --active-or-default-or-filled-state-texts: rgba(14, 32, 54, 1);
    --alt-description-or-detail-or-body-text-color: rgba(0, 0, 0, 1);
    --alt-heading-600-semibold-alt-heading-ALL-CAPS-text-16px-font-family: "Jost-SemiBold", Helvetica;
    --alt-heading-600-semibold-alt-heading-ALL-CAPS-text-16px-font-size: 16px;
    --alt-heading-600-semibold-alt-heading-ALL-CAPS-text-16px-font-style: normal;
    --alt-heading-600-semibold-alt-heading-ALL-CAPS-text-16px-font-weight: 600;
    --alt-heading-600-semibold-alt-heading-ALL-CAPS-text-16px-letter-spacing: 1.2px;
    --alt-heading-600-semibold-alt-heading-ALL-CAPS-text-16px-line-height: 72.20000624656677%;
    --alt-heading-600-semibold-alt-heading-text-16px-font-family: "Jost-SemiBold", Helvetica;
    --alt-heading-600-semibold-alt-heading-text-16px-font-size: 16px;
    --alt-heading-600-semibold-alt-heading-text-16px-font-style: normal;
    --alt-heading-600-semibold-alt-heading-text-16px-font-weight: 600;
    --alt-heading-600-semibold-alt-heading-text-16px-letter-spacing: 0.48px;
    --alt-heading-600-semibold-alt-heading-text-16px-line-height: 72.20000624656677%;
    --body-400-regular-body-text-10px-font-family: "Jost-Regular", Helvetica;
    --body-400-regular-body-text-10px-font-size: 10px;
    --body-400-regular-body-text-10px-font-style: normal;
    --body-400-regular-body-text-10px-font-weight: 400;
    --body-400-regular-body-text-10px-letter-spacing: 0.10000000149011612px;
    --body-400-regular-body-text-10px-line-height: 75.20000338554382%;
    --body-400-regular-body-text-12px-font-family: "Jost-Regular", Helvetica;
    --body-400-regular-body-text-12px-font-size: 12px;
    --body-400-regular-body-text-12px-font-style: normal;
    --body-400-regular-body-text-12px-font-weight: 400;
    --body-400-regular-body-text-12px-letter-spacing: 0px;
    --body-400-regular-body-text-12px-line-height: 120.00000476837158%;
    --body-400-regular-body-text-14px-font-family: "Jost-Regular", Helvetica;
    --body-400-regular-body-text-14px-font-size: 14px;
    --body-400-regular-body-text-14px-font-style: normal;
    --body-400-regular-body-text-14px-font-weight: 400;
    --body-400-regular-body-text-14px-letter-spacing: 0px;
    --body-400-regular-body-text-14px-line-height: 100%;
    --body-400-regular-body-text-16px-font-family: "Jost-Regular", Helvetica;
    --body-400-regular-body-text-16px-font-size: 16px;
    --body-400-regular-body-text-16px-font-style: normal;
    --body-400-regular-body-text-16px-font-weight: 400;
    --body-400-regular-body-text-16px-letter-spacing: 0px;
    --body-400-regular-body-text-16px-line-height: 92.20000505447388%;
    --body-400-regular-body-text-18px-font-family: "Jost-Regular", Helvetica;
    --body-400-regular-body-text-18px-font-size: 18px;
    --body-400-regular-body-text-18px-font-style: normal;
    --body-400-regular-body-text-18px-font-weight: 400;
    --body-400-regular-body-text-18px-letter-spacing: 0px;
    --body-400-regular-body-text-18px-line-height: 75.20000338554382%;
    --body-500-medium-body-medium-10px-font-family: "Jost-Medium", Helvetica;
    --body-500-medium-body-medium-10px-font-size: 10px;
    --body-500-medium-body-medium-10px-font-style: normal;
    --body-500-medium-body-medium-10px-font-weight: 500;
    --body-500-medium-body-medium-10px-letter-spacing: 0.10000000149011612px;
    --body-500-medium-body-medium-10px-line-height: 75.20000338554382%;
    --body-500-medium-body-medium-12px-font-family: "Jost-Medium", Helvetica;
    --body-500-medium-body-medium-12px-font-size: 12px;
    --body-500-medium-body-medium-12px-font-style: normal;
    --body-500-medium-body-medium-12px-font-weight: 500;
    --body-500-medium-body-medium-12px-letter-spacing: 0px;
    --body-500-medium-body-medium-12px-line-height: 75.20000338554382%;
    --body-500-medium-body-medium-14px-font-family: "Jost-Medium", Helvetica;
    --body-500-medium-body-medium-14px-font-size: 14px;
    --body-500-medium-body-medium-14px-font-style: normal;
    --body-500-medium-body-medium-14px-font-weight: 500;
    --body-500-medium-body-medium-14px-letter-spacing: 0px;
    --body-500-medium-body-medium-14px-line-height: 75.20000338554382%;
    --body-500-medium-body-medium-16px-font-family: "Jost-Medium", Helvetica;
    --body-500-medium-body-medium-16px-font-size: 16px;
    --body-500-medium-body-medium-16px-font-style: normal;
    --body-500-medium-body-medium-16px-font-weight: 500;
    --body-500-medium-body-medium-16px-letter-spacing: 0px;
    --body-500-medium-body-medium-16px-line-height: 75.20000338554382%;
    --body-500-medium-body-medium-18px-font-family: "Jost-Medium", Helvetica;
    --body-500-medium-body-medium-18px-font-size: 18px;
    --body-500-medium-body-medium-18px-font-style: normal;
    --body-500-medium-body-medium-18px-font-weight: 500;
    --body-500-medium-body-medium-18px-letter-spacing: 0px;
    --body-500-medium-body-medium-18px-line-height: 75.20000338554382%;
    --cards-bg-color: rgba(247, 251, 255, 1);
    --cards-small-heading-text: rgba(48, 69, 95, 1);
    --danger-caution: rgba(255, 58, 58, 1);
    --description-or-detail-or-body-text: rgba(48, 69, 95, 1);
    --extra-300-light-extra-text-300-light-10px-font-family: "Jost-Light", Helvetica;
    --extra-300-light-extra-text-300-light-10px-font-size: 10px;
    --extra-300-light-extra-text-300-light-10px-font-style: normal;
    --extra-300-light-extra-text-300-light-10px-font-weight: 300;
    --extra-300-light-extra-text-300-light-10px-letter-spacing: 0px;
    --extra-300-light-extra-text-300-light-10px-line-height: 75.20000338554382%;
    --extra-300-light-extra-text-300-light-12px-font-family: "Jost-Light", Helvetica;
    --extra-300-light-extra-text-300-light-12px-font-size: 12px;
    --extra-300-light-extra-text-300-light-12px-font-style: normal;
    --extra-300-light-extra-text-300-light-12px-font-weight: 300;
    --extra-300-light-extra-text-300-light-12px-letter-spacing: 0px;
    --extra-300-light-extra-text-300-light-12px-line-height: 110.00000238418579%;
    --extra-300-light-extra-text-300-light-14px-font-family: "Jost-Light", Helvetica;
    --extra-300-light-extra-text-300-light-14px-font-size: 14px;
    --extra-300-light-extra-text-300-light-14px-font-style: normal;
    --extra-300-light-extra-text-300-light-14px-font-weight: 300;
    --extra-300-light-extra-text-300-light-14px-letter-spacing: 0px;
    --extra-300-light-extra-text-300-light-14px-line-height: 75.20000338554382%;
    --extra-300-light-extra-text-300-light-16px-font-family: "Jost-Light", Helvetica;
    --extra-300-light-extra-text-300-light-16px-font-size: 16px;
    --extra-300-light-extra-text-300-light-16px-font-style: normal;
    --extra-300-light-extra-text-300-light-16px-font-weight: 300;
    --extra-300-light-extra-text-300-light-16px-letter-spacing: 0px;
    --extra-300-light-extra-text-300-light-16px-line-height: 75.20000338554382%;
    --extra-300-light-extra-text-300-light-18px-font-family: "Jost-Light", Helvetica;
    --extra-300-light-extra-text-300-light-18px-font-size: 18px;
    --extra-300-light-extra-text-300-light-18px-font-style: normal;
    --extra-300-light-extra-text-300-light-18px-font-weight: 300;
    --extra-300-light-extra-text-300-light-18px-letter-spacing: 0px;
    --extra-300-light-extra-text-300-light-18px-line-height: 75.20000338554382%;
    --header-name: rgba(14, 32, 54, 1);
    --heading-color: rgba(14, 32, 54, 1);
    --headings-500-medium-heading-h1-28px-font-family: "Jost-Medium", Helvetica;
    --headings-500-medium-heading-h1-28px-font-size: 28px;
    --headings-500-medium-heading-h1-28px-font-style: normal;
    --headings-500-medium-heading-h1-28px-font-weight: 500;
    --headings-500-medium-heading-h1-28px-letter-spacing: 0.28px;
    --headings-500-medium-heading-h1-28px-line-height: 90.70000052452087%;
    --headings-500-medium-heading-h2-24px-font-family: "Jost-Medium", Helvetica;
    --headings-500-medium-heading-h2-24px-font-size: 24px;
    --headings-500-medium-heading-h2-24px-font-style: normal;
    --headings-500-medium-heading-h2-24px-font-weight: 500;
    --headings-500-medium-heading-h2-24px-letter-spacing: 0px;
    --headings-500-medium-heading-h2-24px-line-height: 90.70000052452087%;
    --headings-500-medium-heading-h3-22px-font-family: "Jost-Medium", Helvetica;
    --headings-500-medium-heading-h3-22px-font-size: 22px;
    --headings-500-medium-heading-h3-22px-font-style: normal;
    --headings-500-medium-heading-h3-22px-font-weight: 500;
    --headings-500-medium-heading-h3-22px-letter-spacing: 0px;
    --headings-500-medium-heading-h3-22px-line-height: 90.70000052452087%;
    --headings-500-medium-heading-h4-20px-font-family: "Jost-Medium", Helvetica;
    --headings-500-medium-heading-h4-20px-font-size: 20px;
    --headings-500-medium-heading-h4-20px-font-style: normal;
    --headings-500-medium-heading-h4-20px-font-weight: 500;
    --headings-500-medium-heading-h4-20px-letter-spacing: 0px;
    --headings-500-medium-heading-h4-20px-line-height: 90.70000052452087%;
    --inactive-or-neutral-text-color: rgba(14, 32, 54, 0.6);
    --list-view-and-or-check-items-bg-color: rgba(245, 247, 251, 1);
    --page-bg: rgba(248, 250, 255, 1);
    --primary: rgba(39, 117, 255, 1);
    --primary-color-15: rgba(255, 255, 255, 1);
    --primary-color-3: rgba(255, 255, 255, 1);
    --primary-color-6: rgba(255, 255, 255, 1);
    --secondary-color: rgba(14, 32, 54, 1);
    --secondary-color-10: rgba(14, 32, 54, 0.1);
    --stroke-color-for-inactive-dropdowns-and-comments: rgba(231, 231, 231, 1);
    --success: rgba(17, 201, 121, 1);
    --tertiary-color: rgba(48, 69, 95, 1);
    --text-input-box: rgba(240, 240, 240, 1);
    --warning-pending-status: rgba(255, 144, 64, 1);
  }
  

.heading{
    font-size: 28;
}



.scope-plans-heading {
    align-items: flex-start;
    background-color: var(--Page-BG, #f8faff);
    display: flex;
    gap: 16px;
    padding: 16px 30px;
  }
  @media (max-width: 991px) {
    .scope-plans-heading {
      flex-wrap: wrap;
    }
  }
  .scope-plans-title {
    color: var(--Secondary-Color, #0e2036);
    letter-spacing: 0.28px;
    align-self: center;
    max-width: 542px;
    flex-grow: 1;
    flex-basis: auto;
    margin: auto 0;
    font: 500 28px/90.7% Jost, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  @media (max-width: 991px) {
    .scope-plans-title {
      max-width: 100%;
    }
    .plan-column{
        margin-bottom: 50px;
    }
  }
  .stripe-strip{
    margin: 24px;
    padding: 24px;
    border-radius: 16px;
  }
  .stripe-label{
    margin-left: 6px;
    color: var(--Heading-Color, #0E2036);
    /* Body (500 Medium)/Body Medium 14px */
    font-family: $font-medium;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 75.2%; /* 10.528px */
  }
  .stripe-icon-label{
    display: flex;
    align-items: center;
  }
  .stripe-action-button{
    float: right;
  }
  .stripe-strip-row{
    align-items: center;
  }

  .stripe-verification-card{
      margin: 24px;
      padding: 24px;
      border-radius:16px;
    }
  .stripe-verification-card .status-label{
    color: var(--Primary, #2775FF);
    /* Headings (500 Medium)/Heading h4 20px */
    font: 500 28px/90.7% Jost, -apple-system, Roboto, Helvetica,
      sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 90.7%; /* 18.14px */
  }
  .stripe-verification-card .status-head{
    color: var(--Secondary-Color, #0E2036);
    /* Body (400 Regular)/Body Text 14px */
    font: 500 28px/90.7% Jost, -apple-system, Roboto, Helvetica,
      sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 14px */
  }

  .stripe-verification-card .status-body{
    margin-top: 8px;
    color: var(--Tertiary-Color, #30455F);
    /* Body (400 Regular)/Body Text 12px */
    
    font-family: $font-normal;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
  }

  hr {
    margin-top: 0rem;
  }

  .stripe-verification-card .status-body.text-orange{
    color: var(--danger-caution, #FF3A3A);
  }
  .stripe-verification-card .status-body.text-green{
    color: var(--Success, #11C979);
  }
  @media (max-width: 701px) {
    .stripe-action-button button.small{
        display: unset;
      }
      .stripe-action-button{
        float: none;
        margin-top: 10px;
        text-align: center;
      }
      .stripe-verification-card .status-body{
        margin-bottom: 20px;
      }
  }


  .plans {
    align-items: flex-start;
    background-color: #ffffff;
    border: 1px solid;
    border-color: var(--primary-color-6);
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px;
    position: relative;
    height: 450px;
  }
  .plans.increaseHeight-Monthly{
    height: 500px;
  }
  .plans.increaseHeight-Annual{
    height: 550px;
  }
  
  .plans .frame {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
  
  .plans .free-wrapper {
    align-items: center;
    border-radius: 12px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 3px;
    justify-content: center;
    overflow: hidden;
    padding: 12px 14px;
    position: relative;
  }
  
  .plans .div {
    font-family: $font-medium;
    font-size: 14px;
    letter-spacing: var(--body-500-medium-body-medium-14px-letter-spacing);
    line-height: var(--body-500-medium-body-medium-14px-line-height);
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .plans .frame-2 {
    align-items: center;
    background-color: #ffffff;
    border: 2px solid;
    border-color: var(--success);
    border-radius: 12px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 3px;
    height: 35px;
    justify-content: center;
    overflow: hidden;
    padding: 12px 14px;
    position: relative;
  }
  
  .plans .img {
    height: 12px;
    position: relative;
    width: 12px;
  }
  
  .plans .text-wrapper {
    color: var(--success);
    font-family: $font-medium;
    font-size: 14px;
    letter-spacing: var(--body-400-regular-body-text-14px-letter-spacing);
    line-height: var(--body-400-regular-body-text-14px-line-height);
    margin-top: -3.5px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .plans .element {
    align-self: stretch;
    color: var(--secondary-color);
    position: relative;
  }
  
  .plans .span {
    font-family: $font-medium;
    font-size: 14px;
    letter-spacing: var(--headings-500-medium-heading-h1-28px-letter-spacing);
    line-height: var(--headings-500-medium-heading-h1-28px-line-height);
  }
  
  .plans .span-2 {
    font-family: $font-medium;
    font-size: 14px;
    letter-spacing: var(--body-500-medium-body-medium-18px-letter-spacing);
    line-height: var(--body-500-medium-body-medium-18px-line-height);
  }
  
  .plans .rectangle {
    align-self: stretch;
    background-color: var(--secondary-color-10);
    height: 1px;
    position: relative;
    width: 100%;
  }
  
  .plans .frame-3 {
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: relative;
    width: 100%;
  }
  
  .plans .div-wrapper {
    align-items: center;
    border-radius: 12px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 3px;
    justify-content: center;
    overflow: hidden;
    padding: 12px 14px;
    position: relative;
  }
  
  .plans .free-2 {
    color: var(--cards-bg-color);
    font-family: $font-medium;
    font-size: 14px;
    letter-spacing: var(--body-500-medium-body-medium-14px-letter-spacing);
    line-height: var(--body-500-medium-body-medium-14px-line-height);
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .plans .frame-4 {
    align-items: center;
    background-color: #ffffff;
    border: 2px solid;
    border-color: var(--success);
    border-radius: 12px;
    display: inline-flex;
    flex: 0 0 auto;
    gap: 3px;
    justify-content: center;
    overflow: hidden;
    position: relative;
  }
  
  .plans .frame-5 {
    height: 12px;
    position: relative;
    width: 12px;
  }
  
  .plans .text-wrapper-2 {
    align-self: stretch;
    color: var(--secondary-color);
    font-family: $font-medium;
    font-size: 14px;
    letter-spacing: var(--headings-500-medium-heading-h1-28px-letter-spacing);
    line-height: var(--headings-500-medium-heading-h1-28px-line-height);
    position: relative;
  }
  
  .plans .frame-6 {
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: 7px;
    height: 18px;
    position: relative;
    width: 100%;
  }
  
  .plans .div-2 {
    height: 18px;
    position: relative;
    width: 18px;
  }
  
  .plans .line {
    margin-top: -5px;
  }
  
  .plans .div-3 {
    color: var(--tertiary-color);
    font-family: $font-normal;
    font-size: 14px;
    letter-spacing: var(--body-400-regular-body-text-14px-letter-spacing);
    line-height: var(--body-400-regular-body-text-14px-line-height);
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .plans .frame-7 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    gap: 7px;
    position: relative;
    width: 100%;
  }
  
  .plans .button-label-wrapper {
    all: unset;
    align-items: center;
    align-self: stretch;
    border-radius: 12px;
    box-sizing: border-box;
    display: flex;
    gap: 5px;
    height: 40px;
    justify-content: center;
    overflow: hidden;
    padding: 10px 16px;
    position: relative;
    width: 100%;
  }
  
  .plans .button-label {
    all: unset;
    box-sizing: border-box;
    color: var(--heading-color);
    font-family: "Jost-Medium", Helvetica;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 13.3px;
    position: relative;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
  }
  
  .plans .element-year {
    align-self: stretch;
    color: var(--secondary-color);
    font-family: "Jost-Medium", Helvetica;
    font-size: 28px;
    font-weight: 500;
    line-height: 28px;
    position: relative;
  }
  
  .plans .frame-8 {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 16px;
    position: relative;
    width: 100%;
  }
  
  .plans .button-instance {
    align-self: stretch !important;
    border-radius: 12px !important;
    height: 40px !important;
    overflow: hidden !important;
    width: 100% !important;
  }
  
  .plans .price {
    align-self: stretch;
    position: relative;
  }
  
  .plans .p {
    color: var(--secondary-color);
    flex: 1;
    font-family: "Jost-Medium", Helvetica;
    font-size: 28px;
    font-weight: 500;
    line-height: 28px;
    margin-top: -1px;
    position: relative;
  }
  
  .plans .best-value-badge {
    align-items: center;
    background-color: var(--primary);
    border-radius: 12px;
    box-shadow: 0px 8px 30px #2775ff1a;
    display: inline-flex;
    gap: 5px;
    justify-content: center;
    /* left: 76px; */
    padding: 10px;
    position: absolute;
    top: -19px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    width: 110px;
  }
  
  .plans .union-wrapper {
    height: 16px;
    left: 1px;
    position: relative;
    top: 1px;
    width: 16px;
  }
  
  .plans .union {
    height: 15px;
    left: 1px;
    position: absolute;
    top: 1px;
    width: 15px;
  }
  
  .plans .text-wrapper-3 {
    color: #ffffff;
    font-family: $font-medium;
    font-size: 14px;
    letter-spacing: var(--body-500-medium-body-medium-14px-letter-spacing);
    line-height: var(--body-500-medium-body-medium-14px-line-height);
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }
  
  .plans.monthly {
    overflow: hidden;
  }
  
  .plans.hover {
    box-shadow: 0px 16px 40px 1px #2774ff26;
  }
  
  .plans.free {
    overflow: hidden;
  }
  
  .plans.free.hover {
    height: 320px;
  }
  
  .plans.free.default {
    height: 320px;
  }
  
  .plans.annual .free-wrapper {
    background-color: black;
    color:white;
  }
  
  .plans.monthly .free-wrapper {
    background-color: var(--warning-pending-status);
  }
  
  .plans.free .free-wrapper {
    background-color: var(--primary);
  }
  
  .plans.annual .div {
    color: var(--cards-bg-color);
  }
  
  .plans.monthly .div {
    color: var(--secondary-color);
  }
  
  .plans.free .div {
    color: var(--cards-bg-color);
  }
  
  .plans.hover .frame-2 {
    opacity: 0;
  }
  
  .plans.default .frame-2 {
    opacity: 0;
  }
  
  .plans.hover .img {
    margin-right: -14px;
  }
  
  .plans.purchased .img {
    margin-bottom: -0.5px;
    margin-top: -0.5px;
  }
  
  .plans.default .img {
    margin-bottom: -3316.5px;
    margin-right: -14px;
  }
  
  .plans.free.hover .img {
    margin-bottom: -3742.5px;
  }
  
  .plans.hover.annual .img {
    margin-bottom: -3316.5px;
  }
  
  .plans.hover.monthly .img {
    margin-bottom: -3316.5px;
  }
  
  .plans.annual .element {
    font-family: $font-medium;
    font-size: 14px;
    line-height: var(--headings-500-medium-heading-h1-28px-line-height);
  }
  
  .plans.monthly .element {
    font-family: "Jost-Medium", Helvetica;
    font-size: 28px;
    font-weight: 500;
    line-height: 28px;
  }
  
  .plans.free .element {
    font-family: $font-medium;
    font-size: 14px;
    letter-spacing: var(--headings-500-medium-heading-h1-28px-letter-spacing);
    line-height: var(--headings-500-medium-heading-h1-28px-line-height);
  }
  
  .plans.annual .frame-3 {
    align-items: flex-start;
  }
  
  .plans.default .frame-3 {
    align-items: flex-start;
    gap: 24px;
  }
  
  .plans.free.hover .frame-3 {
    align-items: center;
    gap: 16px;
  }
  
  .plans.free.purchased .frame-3 {
    align-items: center;
    gap: 16px;
  }
  
  .plans.purchased.monthly .frame-3 {
    align-items: center;
    gap: 16px;
  }
  
  .plans.hover.annual .frame-3 {
    gap: 10px;
  }
  
  .plans.purchased.annual .frame-3 {
    gap: 10px;
  }
  
  .plans.hover.monthly .frame-3 {
    align-items: center;
    gap: 16px;
  }
  
  .plans.annual .div-wrapper {
    background-color: var(--secondary-color);
  }
  
  .plans.monthly .div-wrapper {
    background-color: var(--primary);
  }
  
  .plans.free .div-wrapper {
    background-color: var(--primary);
  }
  
  .plans.purchased .frame-4 {
    height: 35px;
    padding: 12px 14px;
  }
  
  .plans.hover .frame-4 {
    height: 31px;
    opacity: 0;
    padding: 10px;
  }
  
  .plans.default .frame-4 {
    height: 31px;
    opacity: 0;
    padding: 10px;
  }
  
  .plans.hover .frame-5 {
    margin-right: -10px;
  }
  
  .plans.purchased .frame-5 {
    margin-top: -0.5px;
  }
  
  .plans.free .frame-5 {
    margin-bottom: -3742.5px;
  }
  
  .plans.default .frame-5 {
    margin-right: -10px;
  }
  
  .plans.default.annual .frame-5 {
    margin-bottom: -2890.5px;
  }
  
  .plans.default.monthly .frame-5 {
    margin-bottom: -2890.5px;
  }
  
  .plans.purchased.monthly .frame-5 {
    margin-bottom: -0.5px;
  }
  
  .plans.hover.annual .frame-5 {
    margin-bottom: -2890.5px;
  }
  
  .plans.purchased.annual .frame-5 {
    margin-bottom: -0.5px;
  }
  
  .plans.hover.monthly .frame-5 {
    margin-bottom: -2890.5px;
  }
  
  .plans.hover .price {
    color: var(--secondary-color);
    font-family: $font-medium;
    font-size: 28px;
    font-weight: 500;
    line-height: 28px;
  }
  
  .plans.default .price {
    align-items: flex-start;
    display: flex;
    flex: 0 0 auto;
    gap: 5px;
    width: 100%;
  }

  
.plan-pricing p{
    display: inline;
}

.plans-instance{
    display: flex;
}
.plan-action-button{
    width: 100%;
    margin-top: auto;
}
.purchase_status{
  font-size: 12px;
}
.purchase_status .if_canceled{
  margin-top: 2px;
  color: red;
}

.plan-page{
  margin-right: 0;
  margin-left: 0;
}