@import url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700&display=swap');

@font-face {
    font-family: Jost;
    src: url(./fonts/Jost-Regular.ttf);
}
@font-face {
    font-family: Jost-Light;
    src: url(./fonts/Jost-Light.ttf);
}
@font-face {
    font-family: Jost-Medium;
    src: url(./fonts/Jost-Medium.ttf);
}
@font-face {
    font-family: Jost-semiBold;
    src: url(./fonts/Jost-SemiBold.ttf);
}