.scope-templetes-heading {
    align-items: flex-start;
    background-color: var(--Page-BG, #f8faff);
    display: flex;
    gap: 16px;
    padding: 10px;
    min-height: 60px;
  }

  .scope-templetes-title {
    color: var(--Secondary-Color, #0e2036);
    letter-spacing: 0.28px;
    align-self: center;
    max-width: 542px;
    flex-grow: 1;
    flex-basis: auto;
    margin: auto 0;
    font: 500 28px/90.7% Jost;
    font-family: $font-medium;
  }

  .scope-templetes-filters {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    margin-top: 16px;
    flex-grow: 1;
    flex-basis: auto;
    justify-content: space-between;
    gap: 16px;
  }

  #password{
    border-right:0px;
  }

 .scope-templetes-filters-option {
    justify-content: center;
    // align-items: flex-start;
    border-radius: 16px;
    border: 1px solid var(--primary-color-15, #fff);
    // backdrop-filter: blur(20px);
    background-color: #fff;
    // align-self: stretch;
    display: flex;
    flex-grow: 1;
    flex-basis: auto;
    gap: 16px;
    padding: 7px 10px;
  }

  .scope-templetes-filters-button {
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0);
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 7px 20px;
    cursor:pointer;
  }
  .scope-templetes-filters-button:hover {
    // justify-content: center;
    // align-items: center;
    border-radius: 8px;
    // border: 1px solid var(--primary-color-6, #fff);
    background-color: var(--Cards-BG-Color, #f7fbff);
    // display: flex;
    // flex-direction: column;
    // flex: 1;
    // padding: 7px 20px;
  }

  .scope-templetes-filters-button.active {
    border-radius: 8px;
    background: linear-gradient(0deg, rgba(39, 117, 255, 0.15) 0%, rgba(39, 117, 255, 0.15) 100%)
  }
  .scope-templetes-filters-field {
    color: var(--Inactive-or-Neutral-Text-Color, rgba(14, 32, 54, 0.6));
    text-align: center;
    align-self: center;
    text-wrap: nowrap;
    font: 400 16px/92.2% Jost, -apple-system, Roboto, Helvetica,
      sans-serif;
  }
  
  .scope-templetes-filters-button:hover .scope-templetes-filters-field {
    color: var(--Primary, #2775ff);
    // font: 500 16px/75.2% Jost, -apple-system, Roboto, Helvetica,
      // sans-serif;
  }
  .scope-templetes-filters-button.active .scope-templetes-filters-field {
    color: var(--Primary, #2775ff);
    // font: 500 16px/75.2% Jost, -apple-system, Roboto, Helvetica,
      // sans-serif;
  }
 
  .scope-templetes-add-new-button {
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 16px;
    border: 1px solid #0E2036;
    align-self: stretch;
    display: flex;
    // width: 139px;
    max-width: 100%;
    gap: 7px;
    padding: 1px 20px;
    cursor: pointer;
  }
  label {
    margin-bottom: 0.0rem;
  }
  .plus {
    margin: 8px 0px;
  }
  .col{
    padding-right: 0px;
    padding-left: 0px;
  }
  .custom-switch .custom-control-label::after {
    background-color: #fff;
  }
  .custom-switch .custom-control-label::before {
    background: #0E20361A;
    border: none;
  }
  .scope-templetes-add-new-title {
    color: var(--Primary, #2775ff);
    text-align: center;
    align-self: center;
    text-wrap: nowrap;
    margin: auto 0;
    font-family: $font-medium;
  }

  .template-name{
    color:$scope-table;
    font-family: $font-normal;
  }
  .template-description, .comment-description, .comment-status-col{
    color:$scope-table-light;
    font-family: $font-light;
  }
  

  .scope-templates-table-heading{
    color: #2775FF;
    font-size: 12px;
    font-family: Jost;
    font-weight: 400;
    line-height: 14.40px;
    word-wrap: break-word
  }
  .template-action{
    display: flex;
    padding: 16px 30px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;
  }

  .template-action-button{
    display:inline-block;
    padding-right: 5px;
  }
  .not-specified{
    color:#ccc;
  }
  .border-radius16{
    border-radius:16px;
  }
  .cursor{
    cursor:pointer;
  }
  .add-email {
    background: white;
    border-radius: 0px 16px 16px 0px;
    margin-top: 10px;
    font-size: 20px;
  }
  .marginright10{
    margin-right:10px
  }
  .margintop20{
    margin-top:20px
  }
  .paddingbottom20{
    padding-bottom:20px
  }
  .padding5{
    padding:5px
  }
  .table{
    background-color: #ffffff;
  }
  .custom-switch .custom-control-label::after {
    // background-color:#ffffff;
  }

  .custom-control-input:checked ~ .custom-control-label::before{
    background-color:$scope-primary;
  }

  .scope-svg-header-after-icon{
    display: inline;
    padding: 0px 0px 0px 20px;
  }

  .header-col {
    display: inline;
    padding: 0 10px 0 0;
  }
  .scope-svg-header-icon {

  }
  .user-inspection-action{
    width:95px; 
    display:inline-flex;
  }
  .table td {
    border: none;
    white-space:unset;
  }
  .table th{
    border: none;
    white-space:nowrap;
  }
  @media (max-width: 991px) {
    .scope-templetes-add-new-title {
      text-wrap: initial;
    }
    .scope-templetes-filters-button:hover {
        text-wrap: initial;
    }
    .scope-templetes-filters-all {
    text-wrap: initial;
    }
    .scope-templetes-filters {
        max-width: 100%;
        flex-wrap: wrap;
    }
    .scope-templetes-filters-option {
        max-width: 100%;
        flex-wrap: wrap;
    }
    .scope-templetes-heading {
        flex-wrap: wrap;
    }
    .scope-templetes-title {
        max-width: 100%;
    }
  }

  @media (max-width: 650px) { 
    .scope-templetes-add-new-title{
        display:none;
    }
    .scope-templetes-add-new-button{
        width: 62px;
        float: right;
        // position: absolute;
        right: 10px;
        // top: 150px;
    }
    .scope-templetes-heading{
        // padding: 100px 10px;
    }
  }
  @media (max-width: 500px) { 
    .scope-templetes-title{
      // margin-top: 100px;
    }
    .template-edit-action-btns {
      margin-top: 20px;
    }
    .template-edit-action-btns div{
      width: auto;
      flex: 1;
    }
  }
  .column-templateName{
    display: flex;
    align-items: center;
  }

  .table-loading{
    top:90px;
  }
  .action_btn_row{
    justify-content: end;
  }
  .titleEditClassForTemplate{
    float: right;
  }

  .template-note-heading{
    font-size: 14px;
    cursor: pointer;
    color: #2775FF;
  }
  .scope-template-info{
    display: flex;
    align-items: center;
    margin-left: 5px;
  }
  .template-note-message{
    cursor: pointer;
    float: right;
    border: 1px solid lightgray;
    border-radius: 5px;
    padding: 0px 5px;
  }
  .template-note-message li, .template-note-message p{
    margin-bottom: 0px;
    font-size: 14px;
  }
  .template-note-message ul{
    margin-bottom: 0;
  }