.new-service-body {
  background-color: white;
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
  width: 100%;
}

.list-item-detail {
  display: flex;
  align-items: center;
}

.custom-checkbox {
  margin-right: 8px;
}

.dropdown-list-item-title {
  flex: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 4px 8px;
}

.dropdown-list-item-fees-blue,
.dropdown-list-item-fees-red {
  margin-left: 8px;
}

.list-item-detail > div:last-child {
  margin-left: 8px;
}

.service-container {
  padding-right: 0px !important;
  padding-left: 0px !important;
  margin: 0px !important;
  max-width: 100% !important;
}

.modifier-header {
  padding: 0px !important;
}

.picker-description {
  // display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.selected-image {
  width: 150px;
  height: 150px;
  border-radius: 22.3px;
  border: 1.39px solid white;
}

.btn-primary {
  font-size: 16px;
  padding: 10px 20px;
  background-color: $scope-primary;
  color: #ffffff;
  border: 1px solid $scope-primary;
  border-radius: 16px;
  width: 118px;
  height: 48px;
  margin-left: 8px;
}

.btn-outline-primary {
  font-size: 16px;
  padding: 10px 20px;
  color: $scope-secondary;
  border: 1px solid $scope-secondary;
  background-color: #ffffff;
  border-radius: 16px;
  width: 118px;
  height: 48px;
  margin-right: 8px;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus {
  background-color: red;
  color: white;
}

.service-icon-primary-text {
  font-family: $font-medium;
  margin-top: 10px;
  font-weight: 500;
  font-size: 14px;
}

.image-container {
  position: relative;
  display: inline-block;
}

.remove-icon {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -30%);
  padding: 5px;
  cursor: pointer;
}

.service-icon-secondary-text {
  font-family: $font-normal;
  font-weight: 400;
  font-size: 10px;
  margin: 0;
  padding: 0;
}
.custom-control-label {
  margin-top: 0px !important;
}

.text-field {
  height: 47px;
  padding: 14px 16px;
  border-radius: 16px;
  border: 1px solid;
  border-color: rgba($color: $scope-secondary, $alpha: 0.1);
  background-color: #f7fbff;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 16px;
}

.new-service-actions {
  display: flex;
}

.table-title-item {
  display: flex;
  align-items: center;
}

.new-service-actions-mobile {
  display: flex;
  justify-content: flex-end;
  padding-bottom: 30px;
}
.new-service-view-mobile {
  padding: 16px;
}

.duration-component {
  // width: 371.33px;
  display: flex;
  gap: 20px;
}

.input-group-text {
  border-top-right-radius: 16px !important;
  border-bottom-right-radius: 16px !important;
  width: 60px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
}

.text-field:focus {
  outline: none;
  border-color: rgba($color: $scope-secondary, $alpha: 0.1);
}

.custom-dropdown {
  min-height: 40px;
  border-radius: 16px;
  border: 1px solid;
  border-color: rgba($color: $scope-secondary, $alpha: 0.1);
  display: flex;
  flex-direction: column;
  padding: 12px;
  justify-content: center;
  position: relative;
  align-items: center;
}

.dropdown-button {
  width: 100%;
  height: 100%;
  padding: 0px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  text-align: left;
  margin: 0px;
  font-family: $font-normal;
  font-size: 16px;
  font-weight: 400;
  color: rgba($color: $scope-secondary, $alpha: 0.6);
}

.dropdown-options {
  list-style: none;
  max-height: 300px;
  overflow-y: auto; 
  overflow-x: hidden; 
  padding: 16px;
  margin: 0;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1;
}

.dropdown-options::-webkit-scrollbar {
  width: 7px;
  height: 12px;
  padding: 5px;
}
.dropdown-options::-webkit-scrollbar-track {
  background: white;
  border-radius: 10px;
  box-sizing: border-box; 
}

.dropdown-options::-webkit-scrollbar-thumb {
  background: rgba($color: $scope-secondary, $alpha: 0.20);
  border-radius: 8px;
  border-right: 1px white solid;
  background-clip: padding-box;
}

.dropdown-options::-webkit-scrollbar-thumb:hover {
  background: rgba($color: $scope-secondary, $alpha: 1);
}

.dropdown-options li:hover {
  background-color: rgba($color: $scope-primary, $alpha: 0.05);
}

.duration-picker-options li:hover {
  background-color: white !important;
  padding: 0px !important;
}

.dropdown-title {
  margin-bottom: 0.5rem;
  font-family: $font-normal;
  font-weight: 400;
  color: rgba($color: $scope-secondary, $alpha: 0.6);
}

.list-item {
  height: 55px;
  padding: 16px 14px 16px 14px;
  border-radius: 14px;
  border-style: solid;
  border-color: transparent;
  background-color: $alice-blue;
  gap: 10px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.list-item-detail {
  display: flex;
  align-items: center;
}

.dropdown-list-item-template {
  // height: 55px;
  padding: 0px 0px 0px 10px;
  border-radius: 14px;
  border-style: solid;
  border-color: transparent;
  background-color: $alice-blue;
  // gap: 10px;
  margin-bottom: 16px;
  // display: flex;
  align-items: center;
}

.dropdown-list-item {
  height: 55px;
  padding: 16px 14px 16px 14px;
  border-radius: 14px;
  border-style: solid;
  border-color: transparent;
  background-color: $alice-blue;
  gap: 10px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.custom-column {
  background-color: $alice-blue;
  padding: 10px;
  border-radius: 5px;
  font-size: 17px;
  margin-left: 10px;
  margin-right: 10px;
}

.custom-column-empty {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  font-size: 17px;
  margin-left: 10px;
  margin-right: 10px;
}

.custom-column-selected {
  background-color: $scope-primary;
  padding: 10px;
  color: white;
  border-radius: 5px;
  font-size: 17px;
  margin-left: 10px;
  margin-right: 10px;
}

.custom-column:hover {
  background-color: $scope-primary;
  color: white;
  cursor: pointer;
}

.duration-picker-item {
  // height: 55px;
  border-radius: 14px;
  border-style: solid;
  border-color: transparent;
  gap: 10px;
  margin-bottom: 16px;
  align-items: center;
}

.fees-details {
  padding: 5px 5px 5px 0px;
  display: flex;
}

.fees-details input {
  width: 100%;
  height: 45px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid;
  border-color: rgba($color: $scope-primary, $alpha: 1);
  box-sizing: border-box;
  font-family: inherit;
  margin-right: 10px;
  font-size: 16px;
}

.template-tile {
  display: flex;
}

.dropdown-list-item {
  height: 55px;
  padding: 16px 14px 16px 14px;
  border-radius: 14px;
  border-style: solid;
  border-color: transparent;
  background-color: $alice-blue;
  gap: 10px;
  margin-bottom: 16px;
  // display: flex;
  align-items: center;
}

label {
  margin-left: 8px;
}

.choose-modifier {
  display: flex;
  justify-content: flex-end;
}
.choose-modifier div{
  cursor: pointer;
}
.modifier-icon-col {
  padding: 0px;
}

.discard-button:hover {
  color: rgba($color: $scope-secondary, $alpha: 0.05);
}

.textfeildlabel {
  padding: 0px 0px 10px 0px;
  font-family: $font-normal;
  margin: 0;
  font-family: $font-normal;
  font-weight: 400;
  color: rgba($color: $scope-secondary, $alpha: 0.6);
}

input[type="checkbox"]:checked {
  background-color: $scope-primary;
  border-color: transparent;
}
input[type="checkbox"]:checked:after {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='9' viewBox='0 0 11 9' fill='none'%3E%3Cpath d='M1.25 4.80886L3.88047 7.47539L9.75 1.52539' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

input[type="checkbox"] {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  width: 20px !important;
  height: 20px !important;
  appearance: none;
  border-radius: 10%;
  border: 1px solid $scope-primary;
  box-shadow: none;
  font-size: 1em;
}

.list-item label {
  font-family: "Jost";
  font-size: 16px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
  align-items: center;
}

.custom-checkbox {
  width: 10px !important;
  position: relative;
}
.pre-inspection-check {
  display: flex;
}
.pre-inspection-check input,
label {
  margin-top: 12.5px;
}

.show-service-check {
  display: flex;
  padding-top: 16px;
}
.show-service-check input,
label {
  margin-top: 12.5px;
}

.textfeild-component {
  flex-direction: column;
  display: flex;
}

.modifiers-component {
  display: flex;
  // justify-content: flex-end;
}

.modifiers-component p {
  font-family: $font-medium;
  font-size: 16px;
  font-weight: 500;
  color: $scope-secondary;
}

.modifier-title-row {
  display: flex;
  justify-content: space-between;
}

.remove-icon {
  position: absolute;
  cursor: pointer;
}

.dropdown-list-item-title {
  font-size: 14px;
  justify-content: start;
  font-family: $font-normal;
}

.dropdown-list-item-fees-blue {
  color: $scope-primary;
  font-weight: 500;
  font-size: 14px;
  margin-left: auto;
  margin-right: 13px;
  font-family: $font-medium;
}

.dropdown-list-item-fees-red {
  color: $red-orange;
  font-weight: 500;
  font-size: 14px;
  margin-left: auto;
  margin-right: 13px;
  font-family: $font-medium;
}

.dropdown-list-item-content {
  flex-grow: 1;
  display: flex;
}

@media screen and (max-width: 1255px) and (min-width: 992px) {
  .col.duration-options{
    flex: 0 0 100% !important;
    margin-right: 10px;
    margin-left: 10px;
  }
}