.comment-main-tabs{
    display: flex;
    width: 100%;
}
.deficiency, .information, .notinspected {
    flex: 0.1;
}

.comment-tabs-link{
    flex: 1;
    text-align: center;
    // padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.deficiency.active {
    border-radius: 12px !important;
    color:#ffffff;
    background: var(--danger-caution, #FF3A3A);
    box-shadow: 0px 10px 8px 0px rgba(41, 111, 199, 0.10);
    flex: 0.8;
}
.information.active {
    border-radius: 12px !important;
    color:#ffffff;
    background: #42A4F3;
    box-shadow: 0px 10px 8px 0px rgba(41, 111, 199, 0.10);
    flex: 0.8;
}
.notinspected.active {
    border-radius: 12px !important;
    color:#ffffff;
    background: #646F7C;
    box-shadow: 0px 10px 8px 0px rgba(41, 111, 199, 0.10);
    flex: 0.8;
}

.deficiency.active .comment-tabs-link .text, .information.active .comment-tabs-link .text, .notinspected.active .comment-tabs-link .text {
    display: unset;
}
.information .comment-tabs-link .text, .deficiency .comment-tabs-link .text, .notinspected .comment-tabs-link .text {
    display: none;
}

.comment-action-tab-active {
    display: inline-block;
    margin: 10px 0px 0px 0px;
}

.modal-comment{
    max-width:610px;
}

.comment-action-tab {
    display: inline-block;
    padding: 10px 15px;
    border-radius: 12px;
    border: 1px solid var(--secondary-color-10, rgba(14, 32, 54, 0.10));
    background: #FFF;
}

.scope-comment-title {
    color: var(--Secondary-Color, #0e2036);
    letter-spacing: 0.28px;
    align-self: center;
    max-width: 542px;
    flex-grow: 1;
    flex-basis: auto;
    margin: auto 0;
    font: 500 28px/90.7% Jost;
    font-family: $font-medium;
  }

  .scope-comment-filters {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    margin-top: 1px;
    flex-grow: 0.2;
    flex-basis: auto;
    justify-content: space-between;
    gap: 16px;
  }
  .scope-comment-search{
    // width:40%;
  }

  .search-icon-box {
    border-radius: 16px 0px 0px 16px;
    padding: 10px;
    background: #fff;
    border: solid 1px #efefef;
    border-right:0px;
  }

  #comment-search-input .input-group-append{
    border-left:0px;
    margin: 0px;
    padding:0px 0px 0px 3px;
  }

  .comment-status-col{
    width: 140px;
  }
  .comment-search-filter-row{
    width: 80%;
    text-align: right;
  }
  .comment-search-filter-row div{
    margin-bottom: 1px;
  }
  .large-medium-display-none{
    width: auto;
    text-align: right;
  }
  @media screen and (min-width: 768px) {
      .large-medium-display-none{
        display: none;
      }
  }
  @media screen and (max-width: 768px) {
      .large-medium-display-block{
        display: none;
      }
      .comment-search-filter-row{
        width: unset;
      }
  }