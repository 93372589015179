.discount-modal-row-1 {
  display: flex;
  width: auto;
  @media (max-width:768px) {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

.error-message {
  color: red;
  font-size: 15px;
}


.discount-modal-row-1 > *:first-child {
  flex: 50;
  margin-right: 10px;
}
.discount-modal-row-1 > *:nth-child(2) {
  flex: 50;
  margin-right: 10px;
}

.discount-check {
  display: flex;
  justify-content: space-around;
  gap: 7px;
  justify-items: auto;
}

.discount-modal-row-2 {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  margin: 0;
  align-items: flex-end;
}

.service-items {
  height: 55px;
  padding: 16px 14px 16px 14px;
  border-radius: 14px;
  border-style: solid;
  border-color: transparent;
  display: flex;
  align-items: center;
}

.service-options {
  border: 1px solid rgba($color: $scope-primary, $alpha: 0.15);
  border-radius: 10px;
  padding: 0px;
  height: 200px;
  overflow: auto;
  align-items: center;
}

.discount-validity{
    border-radius: 16px;
    height: 46px;
    padding: 10px;
    overflow: hidden;
    appearance: none;
    width: 100%;
}

.discount-input{
  border-radius: 10px;
  flex-direction: column;
  display: flex;
  width: 100%;
}

#discount-input-title{
  padding: 5px 7px 10px 0px;
  margin: 0;
  font-family: $font-normal;
  font-weight: 400;
  color: rgba($color: $scope-secondary, $alpha: 0.6);
}

.input-group-1 {
  display: flex;
  border: 1px solid rgba($color: $scope-secondary, $alpha: 1);
  border-radius: 16px;
  height: 46px;
  padding-left: 15px;
  padding-right: 15px;
}

.input-group-1 input,
.input-group-1 select {
  border: none;
  flex: 1; 
}

.input-group-1 select {
  border-left: 1px solid none;
}

.service-items{
  border: none; 
  border-bottom: 1px solid rgba($color: $scope-primary, $alpha: 0.15); 
  border-radius: 0px;
}