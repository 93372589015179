// .modal-scope-modifier {
//   max-width: 100%;
// }

.scope-new-modifier {
  align-items: center;
  border-radius: 16px;
  border: 1px solid $scope-primary;
  display: flex;
  justify-content: center;
  gap: 7px;
  padding: 1px 20px;
  cursor: pointer;
}

.scope-modifier-title {
  color: var(--Primary, #2775ff);
  font-family: $font-medium;
}

.textfeild-component-1 {
  flex-direction: column;
  display: flex;
  width: 100%;
}

.empty-modifier-content {
  text-align: center;
  background-color: white; 
  padding: 20px;
  border-radius: 10px;
}

.empty-modifier-content h2 {
  margin-top: 10px;
  margin-bottom: 5px;
}

.empty-modifier-content h3 {
  margin-bottom: 10px;
}


.text-field-1 {
  height: 46px;
  padding: 5px;
  border-radius: 16px;
  border: 1px solid;
  border-color: rgba($color: $scope-secondary, $alpha: 0.1);
  box-sizing: border-box;
  font-family: inherit;
  font-size: 16px;
}

.text-field-1:focus {
  outline: none;
  border-color: $scope-secondary;
}

.modifier-item-tile {
  display: flex;
  max-width: 100%;
  align-items: start;
}

.modifier-item-tile > *:first-child {
  // flex: 70;
  margin-right: 10px;
}
.modifier-item-tile > *:nth-child(2) {
  // flex: 30;
  margin-right: 10px;
}
