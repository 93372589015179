.navbar {
  font-weight: $font-weight-light;
  background: $navbar-bg;
  transition:background $action-transition-duration $action-transition-timing-function;
  -webkit-transition:background $action-transition-duration $action-transition-timing-function;
  -moz-transition:background $action-transition-duration $action-transition-timing-function;
  -ms-transition:background $action-transition-duration $action-transition-timing-function;
  border-bottom: solid 2px rgba(39, 117, 255, 0.2);

  .navbar-brand {
    width: 110px;
  }
  .navbar-brand-wrapper {
    transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    -webkit-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    -moz-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    -ms-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
    background: $navbar-bg;
    width: $sidebar-width-lg;
    height: $navbar-height;
    :host-context(.sidebar-dark) & {
      background: $sidebar-dark-bg;
    }

    @media (max-width: 991px) {
      width: 55px;
    }
    .navbar-brand {
      color: lighten(color(gray-dark), 20%);
      font-size: 1.5rem;
      line-height: 48px;
      margin-right: 0;
      padding: .25rem 0;
      width: 100%;

      &:active,
      &:focus,
      &:hover {
        color: lighten(color(gray-dark), 10%);
      }

      img {
        width: calc(#{$sidebar-width-lg} - 120px );
        max-width: 100%;
        height: 28px;
        margin: auto;
        vertical-align: middle;
      }
      &.brand-logo-mini {
        display: none;
        img {
          width: calc(#{$sidebar-width-icon} - 50px );
          max-width: 100%;
          height: 28px;
          margin: auto;
        }
      }
    }
  }
  .navbar-menu-wrapper {
    transition: width $action-transition-duration $action-transition-timing-function;
    -webkit-transition: width $action-transition-duration $action-transition-timing-function;
    -moz-transition: width $action-transition-duration $action-transition-timing-function;
    -ms-transition: width $action-transition-duration $action-transition-timing-function;
    color: $navbar-menu-color;
    padding-left: 24px;
    padding-right: 24px;
    // width: calc(100% - #{$sidebar-width-lg});
    height: $navbar-height;
    @media (max-width: 991px) {
      width: auto;
      padding-left: 15px;
      padding-right: 15px;
      height: unset;
    }

    .navbar-toggler {
      border: 0;
      color: inherit;
      height: $navbar-height;
      @include border-radius(0px);
      padding-left: 5px;
      padding-right: 20px;
      &:not(.navbar-toggler-right) {
        font-size: 1.5rem;
        @media (max-width: 991px) {
          display: none;
        }
      }
      &.navbar-toggler-right{
        @media (max-width:991px){
          padding-left: 15px;
          padding-right: 11px;
          border-right: none;
        }
      }
    }

    .search-field {
      .input-group {
        input {
          font-size: $default-font-size;
          padding: .5rem;
          @include input-placeholder {
            font-size: $navbar-font-size;
            color: $navbar-menu-color;
            font-weight: $font-weight-light;
          }
        }
        i {
          font-size: 17px;
          margin-right: 0;
          color: $navbar-menu-color;
        }
        .input-group-text {
          background: transparent;
        }
      }
      .rtl & {
        @extend .ml-0;
        @extend .mr-4;
      }
    }

    .count-indicator {
      position: relative;

      .count-symbol,
      .count-number {
        position: absolute;
        border-radius: 100%;
      }
      .count-symbol {
        top: 18px;
        right: 0px;
        width: 7px;
        height: 7px;
      }
      .count-number {
        min-width: 14px;
        height: 14px;
        font-size: .5rem;
        color: $white;
        bottom: 16px;
        right: -5px;
        line-height: 1;
        text-align: center;
      }
      &:after {
        display: none;
      }
    }
    .navbar-nav {
      flex-direction: row;
      align-items: center;
      .nav-item {
        .nav-link {
          color: inherit;
          font-size: $navbar-font-size;
          margin-left: 1.5rem;
          margin-right: 1.5rem;
          height: $navbar-height;
          @include display-flex;
          @include align-items(center);
          cursor: pointer;
          @media (max-width: 767px) {
            margin-left: .8rem;
            margin-right: .8rem;
          }
          i {
            font-size: $navbar-icon-font-size;
          }
        }
        .dropdown {
          .dropdown-toggle {
            border: 0;
            padding: 0;
            background: transparent;
            font-weight: $font-weight-light;
            &:focus {
              box-shadow: none;
            }
            &:after {
              color: theme-color(primary);
              font-size: 1rem;
            }
          }
          .dropdown-menu {
            @extend .dropdownAnimation;
            margin-top: 0;
            border: none;
            @include border-radius(5px);
            -webkit-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 3px 21px 0px rgba(0, 0, 0, 0.2);
            &.navbar-dropdown {
              .rtl & {
                right: auto;
                left: 0;
              }

              .dropdown-item {
                @extend .d-flex;
                @extend .align-items-center;
                margin-bottom: 0;
                padding: 11px 13px;
                cursor: pointer;

                i {
                  font-size: 17px;
                }

                .ellipsis {
                  max-width: 200px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
                .rtl & {
                  i{
                    @extend .mr-0;
                    margin-left: 10px;
                  }
                }
              }

              .dropdown-divider {
                margin: 0;
              }
            }
          }
          @media (max-width: 767px) {
            position: static;
            .navbar-dropdown {
              top: $navbar-height;
              width: 100%;
            }
          }
        }
        &.nav-settings {
          padding-left: 20px;
          padding-right: 5px;
          .nav-link{
            margin-right: 0;
            margin-left: 0;
            background-color: transparent;
          }
        }
        &.nav-profile {
          .nav-link {
            @extend .d-flex;
            .nav-profile-img {
              position: relative;
              width: 32px;
              height: 32px;
              img {
                width: 32px;
                height: 32px;
                border-radius: 100%;
              }
              .availability-status {
                position: absolute;
                width: 10px;
                height: 10px;
                border-radius: 100%;
                border: 2px solid #ffffff;
                bottom: 5px;
                right: -5px;
                &.online {
                  background: theme-color(success);
                }
                &.offline {
                  background: theme-color(danger);
                }
                &.busy {
                  background: theme-color(warning);
                }
              }
            }
            .nav-profile-text {
              margin-left: 1.25rem;
              .rtl & {
                margin-left: 0;
                margin-right: 1.25rem;
              }
              p {
                line-height: 1;
              }
              @media (max-width: 767px) {
                display: none;
              }
            }
            &.dropdown-toggle {
              &:after {
                line-height: 2;
              }
            }
          }
        }
      }
      &.navbar-nav-right {
        @media (min-width: 992px) {
          margin-left: auto;
          .rtl & {
            margin-left: 0;
            margin-right: auto;
          }
        }
      }
    }
  }
}
@media (max-width:991px) {
  .margin-top-10{
    margin-top: 10px;
  }
  // .navbar {
  //   flex-direction: row;
  //   .navbar-brand-wrapper {
  //     width: 75px;
  //     .navbar-brand {
  //       &.brand-logo {
  //         display: none;
  //       }
  //       &.brand-logo-mini {
  //         display: inline-block;
  //       }
  //     }
  //   }
  // }

  // .navbar-collapse {
  //   display: flex;
  //   margin-top: 0.5rem;
  // }
}


@media (max-width:480px) {
  .navbar {
    .navbar-brand-wrapper {
      // width: 55px;
      .brand-logo-mini{
        padding-top: 0px;
      }
    }
  }
}
@media (max-width:485px) {
  .navbar-brand-wrapper-scope {
    // width: 190px !important;
  }
  .navbar .navbar-menu-wrapper{
    padding: 0px;
  }
  .scope-comment-search {
    // width: 55% !important;
  }
}

/* Navbar color variations */
@each $color, $value in $theme-gradient-colors {
  .navbar {
    &.navbar-#{$color} {
      .navbar-menu-wrapper {
        background: $value;
        color: $white;
        .nav-profile-text {
          p {
            @extend .text-white;         
          }
        }
        .search-field {
          .input-group {
            input {
              @include input-placeholder {
                color: $white;
              }
            }
            i {
              color: $white;
            }
          }
        }
      }
    }
  }
}

.scope-nav{
  display: flex;
  // padding: 16px;
  padding-top: 15px;
  align-items: start;
  gap: 5px;
  align-self: stretch;
}
.nav-item-active{
  color:$scope-secondary;
  // border-radius: 16px;
  border: 0px solid var(--primary-color-6, #FFF);
  background: $sidebar-light-menu-active-bg;
  // background: var(--primary-color-15, linear-gradient(0deg, rgba(39, 117, 255, 0.15) 0%, rgba(39, 117, 255, 0.15) 100%), #FFF);
}

.nav-item-li{
  // border-radius: 16px;
  border: 0px solid var(--secondary-color-10, rgba(14, 32, 54, 0.10));
  background: #FFF;
  width:100%;
}

.scope-menu-icon{
  display: flex;
  width: 18px;
  height: 18px;
  padding: 0px 16px 0px 0px;
  justify-content: center;
  align-items: center;
}

.scope-svg-menu-icon{
  // display: block;
  // padding: 0px 15px 0px 0px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.sidebar-icon-only .sidebar .nav .nav-item .nav-link .scope-svg-menu-icon {
  padding: 0 1px;
}

.icon-broder-shadow{
  border-radius: 73.171px;
  // padding: 5.122px;
  background: #FFF;
  box-shadow: 0px 2.92683px 6.58537px 0px rgba(39, 117, 255, 0.24);
}

.icon-menu{
  display: flex;
  padding: 5.122px;
  justify-content: center;
  align-items: center;
  gap: 7.317px;
}

.icon-setting{
  display: flex;
  width: 38.824px;
  height: 38.824px;
  padding: 2.235px 2.776px 2.235px 2.812px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 26.882px;
  background: linear-gradient(0deg, rgba(39, 117, 255, 0.06) 0%, rgba(39, 117, 255, 0.06) 100%);
}

.icon-message{
  display: flex;
  width: 18px;
  height: 18px;
  padding: 1.688px 0.938px 0.996px 0.938px;
  justify-content: center;
  align-items: center;
}

.navbar-brand-wrapper-scope {
  transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -webkit-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -moz-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  -ms-transition: width $action-transition-duration $action-transition-timing-function, background $action-transition-duration $action-transition-timing-function;
  background: $navbar-bg;
  width: $sidebar-width-lg;
  height: $navbar-height;
  padding: $navbar-padding;
  z-index: 11;
  .navbar-brand {
    color: lighten(color(gray-dark), 20%);
    font-size: 1.5rem;
    line-height: 55px;
    margin-right: 0;
    padding: .25rem 0;
    // width: 100%;

    &:active,
    &:focus,
    &:hover {
      color: lighten(color(gray-dark), 15%);
    }
    svg {
      vertical-align: unset;
    }
    img {
      // width: calc(#{$sidebar-width-lg} - 120px );
      max-width: 100%;
      // height: 28px;
      max-height: 60px;
      margin: auto;
      vertical-align: middle;
    }
    &.brand-logo-mini {
      display: none;
      img {
        width: calc(#{$sidebar-width-icon} - 50px );
        max-width: 100%;
        height: 28px;
        margin: auto;
      }
    }
  }
  .org-name{
    display:table;
  }
}

.loginwith{
  color: var(--Secondary-Color, #0E2036);
text-align: center;
align-self: stretch;
/* Headings (500 Medium)/Heading h2 24px */
font-family: Jost;
font-size: 24px;
font-style: normal;
font-weight: 500;
line-height: 90.7%; /* 21.768px */
}

.social-login{
  display: inline;
}

.social-login-button{
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
}
.company-logo{
  position: absolute;
  z-index: 9;
  width: 100%;
  @media (max-width: 700px) {
    // display: none !important;
    position:relative;
  }
}
.mobile{
  display : none !important;
}
@media (max-width: 700px) {
  .navbar-brand-wrapper-scope {
    width: 100% !important;
  }
  .navbar-toggler{
    position: absolute;
    left: 10px;
  }
  .desktop{
    display : none !important;
  }
  .mobile{
    display : block !important;
  }
}